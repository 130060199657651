import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-modal'
import { TelegramClient } from 'messaging-api-telegram'
import SuccessSubmit from '@components/SuccessSubmit'
import telegram from '@global/telegram'

const customStyles = {
  content: {
    top: '0',
    left: 'auto',
    right: '0',
    bottom: 'auto'
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)'
  }
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#astex')

export default function ServicesFrom (props) {
  const { title, setStatus } = props
  const [modalIsOpen, setIsOpen] = useState(false)

  const [name, setName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')

  const [validName, setValidName] = useState(true)
  const [validCompanyName, setValidCompanyName] = useState(true)
  const [validPhone, setValidPhone] = useState(true)
  const [validMail, setValidMail] = useState(true)

  const [submitStatus, setSubmitStatus] = useState(false)

  const client = new TelegramClient({
    accessToken: process.env.REACT_APP_TELEGRAM_BOT
  })

  const nameChange = (e) => {
    setName(e.target.value)
    setValidName(true)
  }

  const companyNameChange = (e) => {
    setCompanyName(e.target.value)
    setValidCompanyName(true)
  }

  const phoneChange = (e) => {
    setPhone(e.target.value)
    setValidPhone(true)
  }

  const mailChange = (e) => {
    setMail(e.target.value)
    setValidMail(true)
  }

  const messageChange = (e) => {
    setMessage(e.target.value)
  }

  const validateField = (fieldName, value) => {
    // const {name, phone, email} = this.state
    let valid
    switch (fieldName) {
      case 'name':
        valid = value.length >= 1
        setValidName(valid)
        return valid
      case 'companyName':
        valid = value.length >= 1
        setValidCompanyName(valid)
        return valid
      case 'phone':
        // valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        valid = true
        setValidPhone(valid)
        return valid
      case 'mail':
        valid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setValidMail(valid)
        return valid
      default:
        return null
    }
  }

  const thanksModal = () => {
    const content = submitStatus
      ? <div>
        <h2>заявка <span>отправлена</span></h2>
        <p>Наш менеджер свяжется с вами в течении 2 дней</p>
        </div>
      : null
    return content
  }

  const refresh = () => {
    setName('')
    setCompanyName('')
    setPhone('')
    setMessage('')
    setMail('')
  }

  const submit = (e) => {
    e.preventDefault()

    // alert(`Form data: ${data}`)
    // console.log('Form data: ', data)

    validateField('name', name)
    validateField('companyName', companyName)
    validateField('mail', mail)
    validateField('phone', phone)

    const ids = telegram()

    if (validateField('name', name) && validateField('companyName', companyName) && validateField('mail', mail) && validateField('phone', phone)) {
      ids.map((item) =>
        client.sendMessage(item,
          `<b>ЗАКАЗ УСЛУГИ:</b>${title}\n\n` +
              `<b>Фамилия и имя: </b> ${name} \n` +
              `<b>Контактный номер: </b> <a href="tel:${phone}">${phone}</a> \n` +
              `<b>Email: </b> <a href="mailto:${mail}">${mail}</a> \n` +
              `<b>Опишите ваш заказ: </b> ${companyName} \n` +
              `<b>Комментарий: </b> ${message} \n`,
          {
            disableWebPagePreview: true,
            disableNotification: true,
            parseMode: 'HTML'
          })
      )
      setSubmitStatus(true)
      refresh()
    }
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setSubmitStatus(false)
  }

  return (
    <div>
      <button onClick={openModal} className='button'>ЗАКАЗАТЬ</button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='modal-content modal-services'>
          <div className='modal-close' onClick={closeModal}>+</div>

          <div className='modal-content-top'>
            {submitStatus
              ? <div>
                <h2>заявка <span>отправлена</span></h2>
                <p>Наш менеджер свяжется с вами в течении 2 дней</p>
                </div>
              : <h2>ЗАКАЗ УСЛУГИ: <span dangerouslySetInnerHTML={{ __html: title }} /></h2>}
          </div>
          <div className='modal-content-bottom for-form'>
            {submitStatus
              ? <button onClick={closeModal}>закрыть</button>
              : <div>
                <p>Оставьте нам ваши контактные данные и кратко опишите заказ. Наш менеджер свяжется с вами в течении 2 дней</p>
                <form onSubmit={submit}>
                  <label>
                    Фамилия и имя
                  </label>
                  <input
                    className={validName ? null : 'not-valid'} value={name} onChange={nameChange} type='text'
                    placeholder='Иванов Иван'
                  />

                  <label>
                    Контактный номер телефона
                  </label>
                  <input
                    type='phone' className={validPhone ? null : 'not-valid'} value={phone} onChange={phoneChange}
                    placeholder='+38 ХХХ - ХХХ - ХХ - ХХ'
                  />

                  <label>
                    Email для связи
                  </label>
                  <input
                    className={validMail ? null : 'not-valid'} type='email' value={mail} onChange={mailChange}
                    placeholder='ivan.ivanov@mail.com'
                  />

                  <label>
                    Опишите ваш заказ
                  </label>
                  <textarea
                    className={validCompanyName ? null : 'not-valid'} value={companyName}
                    onChange={companyNameChange} placeholder='Опишите ваш заказ'
                  />

                  <label>
                    Комментарий (необязательно)
                  </label>
                  <textarea value={message} onChange={messageChange} placeholder='Ваш текст' />

                  <button className='button' type='submit'>Отправить</button>
                </form>
                </div>}

          </div>
        </div>
      </Modal>
    </div>

  )
}

ServicesFrom.propTypes = {
  setStatus: PropTypes.func,
  title: PropTypes.any
}
