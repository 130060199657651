import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-multi-lang'

export default function ThirdScreen (props) {
  const [first_quote, setQuote] = useState()
  const [first_about_company, setAbout] = useState()

  const t = useTranslation()

  useEffect(() => {
    setQuote(props.first_quote)
    setAbout(props.first_about_company)
  }, [props.first_quote, props.first_about_company])

  return (
    <section id='third-screen' className='third-screen'>
      <div className='container'>
        <div className='column-1-4'>
          <p className='quote'>
            {first_quote}
          </p>
        </div>
        <div className='column-3-4'>
          <div dangerouslySetInnerHTML={{ __html: first_about_company }} />

          <hr />

          {/*<div className='statistic-info'>*/}
          {/*  <div className='single-statistic'>*/}
          {/*    <span className='h1'>75</span>*/}
          {/*    <p>{t('pages.home.text_1')}</p>*/}
          {/*  </div>*/}
          {/*  <div className='single-statistic'>*/}
          {/*    <span className='h1'>30</span>*/}
          {/*    <p>{t('pages.home.text_2')}</p>*/}
          {/*  </div>*/}
          {/*  <div className='single-statistic'>*/}
          {/*    <span className='h1'>985</span>*/}
          {/*    <p>{t('pages.home.text_3')}</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </section>
  )
}

ThirdScreen.propTypes = {
  first_quote: PropTypes.string,
  first_about_company: PropTypes.string
}
