import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { Link, useParams } from 'react-router-dom'

import { serviceContent, renderPosts, showLoader, hideLoader, setCurrentLang } from '@global/globalFunctions'
import NotFound from '@pages/404/index'
import Seo from '@components/Seo'

import '@scss/service/index.scss'

const SingleServices = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [servicePage, setServicePage] = useState()
  const [posts, setPosts] = useState()
  const [stateUrl, setStateUrl] = useState()
  const [loading, setLoading] = useState(true)

  const params = useParams()
  const t = useTranslation()

  async function fetchPosts () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}posts?lang=${lang}`)
    res.json()
      .then((res) => setPosts(res))
      .catch((err) => setErrors(err))
  }

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}services?lang=${lang}&label=${params.label}&region=${region}`)
    res.json()
      .then((res) => setServicePage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  // console.log(servicePage)

  useEffect(() => {
    showLoader()
    fetchPosts()
    fetchData()

    dispatch(setUrl(`/company/our-work/services/${params.label}`))
    dispatch(setLang(lang))
    dispatch(setRegion(region))
    setLanguage(lang)
    setCurrentLang(region, lang, setStateUrl)
  }, [lang, params, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!servicePage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages single-services-page'>
        <Seo seo={{ title: servicePage.seo_title, canonical: servicePage.seo_canonical, description: servicePage.seo_description }} />

        <section className='first-screen'>
          <div className='container title-place'>
            <div className='container-title'>
              <h1 dangerouslySetInnerHTML={{ __html: servicePage.title }} />
            </div>
            <div className='column-1-4' />
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: servicePage.about_service }} />
          </div>

          {servicePage.texts ? serviceContent(servicePage.texts, servicePage.title) : null}

          <div className='container posts'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h2>{t('global.titles.usefullInfo')}</h2>
              {posts ? renderPosts(posts.slice(0, 3), stateUrl) : null}

              <Link to='/blog' className='button'>{t('global.buttons.detail')}</Link>
            </div>
          </div>
        </section>

      </div>
    )
  }
}

SingleServices.defaultProps = {
  lang: 'ru',
  url: '/',
  region: 'ukraine'
}

SingleServices.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(SingleServices)
