import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { Link } from 'react-router-dom'

import { hideLoader, showLoader } from '@global/globalFunctions'

import NotFound from '@pages/404/index'
import FormContact from '@containers/FormContact'
import Seo from '@components/Seo'

import Maps from '@components/Map'

import '@scss/contact/index.scss'

const team = (data) => {
  const content = data.map((item, i) =>
    <div key={`team${i}`} className='team-card single-type'>
      <div className='number'>
        0
        {i + 1}
      </div>
      <div className='content'>
        <h4>{item.name}</h4>
        <div className='post-content' dangerouslySetInnerHTML={{ __html: item.info }} />
      </div>
    </div>
  )

  return content
}

const Contact = (props) => {
  const { dispatch, lang, url, region } = props
  const [factoryLocation, setFactoryLocation] = useState()
  const getGeoFromRegion = () => {
    switch (region) {
      case 'ukraine':
        return ({ lat: 49.985095, lng: 36.211391 })
      case 'russia':
        return ({ lat: 55.65412745030144, lng: 37.556925687020936 })
      default:
        return ({ lat: 49.985095, lng: 36.211391 })
    }
  }

  const [hasError, setErrors] = useState(false)
  const [contactPage, setContactPage] = useState({
    title: '',
    first_text: '',
    guarantee_text: ''
  })

  const [loading, setLoading] = useState(true)
  const t = useTranslation()
  console.log(contactPage)
  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}contact?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setContactPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)

    setFactoryLocation(getGeoFromRegion())
    // console.log('LANG',lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!contactPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages contact-page'>
        <Seo seo={{ title: contactPage.seo_title, canonical: contactPage.seo_canonical, description: contactPage.seo_description }} />

        <section className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: contactPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: contactPage.first_text }} />
          </div>
        </section>

        <section className='second-screen'>
          <div className='container'>
            <div className='column-1-4'>
              <h4>{t('pages.contact.mainOffice')}</h4>
              <div dangerouslySetInnerHTML={{ __html: contactPage.main_contact }} />
            </div>
            <div className='column-3-4'>
              <div className='map-container'>
                <Maps location={factoryLocation} />
              </div>
            </div>
          </div>
        </section>

        <section className='third-screen'>
          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <div className='team-block'>
                <h4>{t('pages.contact.director')}</h4>
                {contactPage.chiefs ? team(contactPage.chiefs) : null}
              </div>

              <div className='team-block'>
                <h4>{t('pages.contact.saleDepartment')}</h4>
                {contactPage.sales ? team(contactPage.sales) : null}
              </div>

              <div className='team-block'>
                <h4>{t('pages.contact.marketing')}</h4>
                {contactPage.marketing ? team(contactPage.marketing) : null}
              </div>

              <div className='team-block'>
                <h4>{t('pages.contact.teh')}</h4>
                {contactPage.technical ? team(contactPage.technical) : null}
              </div>

              <div className='team-block'>
                <h4>{t('pages.contact.buy')}</h4>
                {contactPage.customers ? team(contactPage.customers) : null}
              </div>

              <div className='team-block'>
                <h4>{t('pages.contact.logistic')}</h4>
                {contactPage.logistic ? team(contactPage.logistic) : null}
              </div>
            </div>
          </div>
        </section>

        <section className='fourth-screen'>
          <div className='container-full'>
            <img className='img-full' src={contactPage.file} alt='Vacancies page' />
          </div>
          <div className='container for-form'>
            <div className='column-1-4' />

            <div className='column-3-4'>
              <h3>{t('pages.contact.formTitle')}</h3>
              <p>{t('pages.contact.formText')}</p>
              <FormContact region={region} />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

Contact.defaultProps = {
  lang: 'ru',
  url: '/contact/main-office',
  region: 'ukraine'
}

Contact.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Contact)
