import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function Accordion (props) {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({
    title: '',
    content: ''
  })
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setData(props.data)
    setIndex(props.index)
  }, [props.data, props.index])

  return (
    <div className={visible ? 'accordion accordion-visible' : 'accordion'} onClick={() => setVisible(!visible)}>
      <div className='number'>
        {index > 8 ? index + 1 : `0${index + 1}`}
      </div>
      <div className='content'>
        <div className='accordion-title'>
          <h4>{data.title}</h4>
        </div>
        <div className='accordion-content'>
          <p>{data.text}</p>
        </div>
      </div>
      <div className='plus'>+</div>
    </div>
  )
}

Accordion.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number
}
