import React from 'react'

import './index.scss'

const WSColorSelector = ({
  bg,
  text,
  action,
  isSelected,
  setImage,
  photo,
  url,
  setLink
}) => {
  const style = {
    backgroundColor: bg,
    border: isSelected === text ? '1px solid black' : 'none'
  }

  const clickHandler = (event) => {
    action(event)
    setImage(photo)
    setLink(url)
  }

  return (
    <div onClick={clickHandler} id={text} className='color-picker-item'>
      <span style={style} className='color' />
      <span className='text'>{text}</span>
    </div>
  )
}

export default WSColorSelector
