import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setRegion, setUrl } from '@global/actions'

import TeamSlider from '@components/team/TeamSlider/index'
import { textContent, showLoader, hideLoader } from '@global/globalFunctions'

import Seo from '@components/Seo'
import NotFound from '@pages/404/index'

import '@scss/team/index.scss'
import '@scss/inner-pages/index.scss'

const Team = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [teamPage, setTeamPage] = useState()

  const [loading, setLoading] = useState(true)

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}team?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setTeamPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    dispatch(setUrl(url))
  }, [])

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }
  // console.log('Team', teamPage)
  if (loading) {
    return null
  } else if (!teamPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages team-page'>
        <Seo seo={{ title: teamPage.seo_title, canonical: teamPage.seo_canonical, description: teamPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: teamPage.title }} />
            </div>
            <div className='column-3-4 sub-title-text' dangerouslySetInnerHTML={{ __html: teamPage.first_text }} />
          </div>
          <div className='container-full'>
            <TeamSlider teams={teamPage.teams} />
          </div>
        </section>
        <section id='second-screen' className='second-screen'>
          {teamPage.teams_content ? textContent(teamPage.teams_content) : null}
        </section>
      </div>
    )
  }
}

Team.defaultProps = {
  lang: 'ru',
  url: '/company/office',
  region: 'ukraine'
}

Team.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Team)
