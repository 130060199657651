import React, { useEffect, useState } from 'react'
import Poster from '../../../../assets/img/poster.jpeg'

function textContent (data) {
  const content = data.map((item, i) => (
    <div key={`aboutCompany${i}`} className='container'>
      {item.quote ? (
        <div className='column-1-4'>
          <p className='quote'>{item.quote}</p>
        </div>
      ) : (
        <div className='column-1-4'>
          <p className='quote' />
        </div>
      )}
      {item.file ? (
        <div className='column-3-4'>
          <video controls='controls' poster={Poster}>
            <source src={item.file} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          </video>
        </div>
      ) : <div className='column-3-4' dangerouslySetInnerHTML={{ __html: item.text }} />}
    </div>
  ))

  return content
}

export default function FifthScreen (props) {
  const [second_about_company, setAbout] = useState()

  useEffect(() => {
    setAbout(props.second_about_company)
  }, [props.second_about_company])

  return (
    <section id='fifth-screen' className='fifth-screen'>
      {second_about_company ? textContent(second_about_company) : null}
    </section>
  )
}
