import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { addToCart, plusToCart } from '@containers/Cart/actions'

const CartItem = (props) => {
  const { dispatch, product } = props

  const onPlus = (val) => {
    dispatch(plusToCart(product, val))
  }
  return (
    <div className='cartItem'>
      <div className='productImage'>
        <img src={product.img} alt='Product img' />
      </div>
      <div className='productInfo'>
        <Link to={`/catalog/products/${product.label}`}>
          <p className='p-bold'>{product.title}</p>
          <p>{product.desc}</p>
        </Link>
      </div>
      <div className='cost'>
        <span>{product.price} грн <span>/ 1 шт</span></span>
      </div>

      <div className='count'>
        <div className='countRow'>
          <div className='minus' onClick={() => onPlus(product.count - 1)}>-</div>
          <input defaultValue={product.count} value={product.count} type='number' />
          <div className='plus' onClick={() => onPlus(product.count + 1)}>+</div>
        </div>
      </div>

      <div className='totalCost'>
        <span>{product.price * product.count} грн </span>
      </div>
      <div className='remove'>
        <div className='removeBtn' onClick={() => onPlus(0)}>+</div>
      </div>
    </div>
  )
}

CartItem.propTypes = {
  dispatch: PropTypes.func,
  product: PropTypes.any
}

export default connect(null, null)(CartItem)
