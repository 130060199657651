import React from 'react'
import PropTypes from 'prop-types'

const CheckBox = (props) => {
  const { click, label, children, selector } = props

  const isActive = selector.includes(label)

  const toggleCheck = () => {
    click(label, isActive)
  }

  return (
    <label className={isActive ? 'active' : null}>
      <input onClick={toggleCheck} type='checkbox' />
      <span>{children}</span>
    </label>
  )
}

CheckBox.propTypes = {
  click: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.any
}

export default CheckBox
