import React, { useEffect, useState } from 'react'

import FirstStep from '@containers/Registration/FirstStep/index'
import SecondStep from '@containers/Registration/SecondStep/index'
import ThirdStep from '@containers/Registration/ThirdStep/index'
import FourthStep from '@containers/Registration/FourthStep/index'

import '@scss/inner-pages/index.scss'

export default function Registration () {
  const [firstStep, setFirstStep] = useState('progress')
  const [secondStep, setSecondStep] = useState('todo')
  const [thirdStep, setThirdStep] = useState('todo')
  const [fourthStep, setFourthStep] = useState('todo')

  function goSecondStep () {
    setFirstStep('done')
    setSecondStep('progress')
  }

  function goThirdStep () {
    setSecondStep('done')
    setThirdStep('progress')
  }

  function goFourthStep () {
    setThirdStep('done')
    setFourthStep('progress')
  }

  return (
    <div className='page-content inner-pages registration-page'>
      <section id='first-screen' className='first-screen'>
        <div className='container'>
          <div className='column-1-4'>
            <h1>регистрация</h1>
          </div>
          <div className='column-3-4' />
        </div>
        <div className='container'>
          <div className='column-1-4'>
            <ul>
              <li className={(firstStep === 'progress') ? 'active-step' : (firstStep === 'done') ? 'done-step' : 'block-step'}>
                <span>01</span> ИНН организации
              </li>

              <li className={(secondStep === 'progress') ? 'active-step' : (secondStep === 'done') ? 'done-step' : 'block-step'}>
                <span>02</span> Юридический адрес организации
              </li>

              <li className={(thirdStep === 'progress') ? 'active-step' : (thirdStep === 'done') ? 'done-step' : 'block-step'}>
                <span>03</span> Персональная информация
              </li>

              <li className={(fourthStep === 'progress') ? 'active-step' : (fourthStep === 'done') ? 'done-step' : 'block-step'}>
                <span>04</span> бЕЗОПАСНОСТЬ
              </li>
            </ul>
          </div>
          <div className='column-3-4'>
            {(firstStep === 'progress') ? <div className='registration-step for-form'>
              <h4>ИНН ОРГАНИЗАЦИИ</h4>
              <p>Пожалуйста, введите ИНН Вашей организации. Все поля обязательны для заполнения.</p>
              <FirstStep send={() => goSecondStep()} />
            </div> : null}

            {(secondStep === 'progress') ? <div className='registration-step for-form'>
              <h4>Юридический адрес организации</h4>
              <p>Пожалуйста, введите юридические данные и адреса Вашей организации. </p>
              <SecondStep send={() => goThirdStep()} />
            </div> : null}

            {(thirdStep === 'progress') ? <div className='registration-step for-form'>
              <h4>Персональная информация</h4>
              <p>Пожалуйста, сообщите нам Ваше имя и контакт для завершения</p>
              <ThirdStep send={() => goFourthStep()} />
            </div> : null}
            {(fourthStep === 'progress') ? <div className='registration-step for-form'>
              <h4>Персональная информация</h4>
              <p>Пожалуйста, сообщите нам Ваше имя и контакт для завершения</p>
              <FourthStep send={() => goFourthStep()} />
            </div> : null}
          </div>
        </div>
      </section>
    </div>
  )
}
