import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

const Seo = (props) => {
  const { seo } = props

  const [stateSeo, setStateSeo] = useState(seo)

  useEffect(() => {
    setStateSeo(seo)
  }, [seo])

  return (
    <Helmet>
      <title>{stateSeo.title || 'Title'}</title>
      <link rel='canonical' href={stateSeo.canonical || 'canonical'} />
      <meta name='description' content={stateSeo.description || 'description'} />
    </Helmet>
  )
}
Seo.defaultProps = {
  seo: {
    title: 'Title',
    canonical: 'canonical',
    description: 'description'
  }
}

Seo.propTypes = {
  seo: PropTypes.object
}

export default Seo
