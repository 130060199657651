import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation, setLanguage } from 'react-multi-lang'
import { useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { renderProducts, showLoader, hideLoader } from '@global/globalFunctions'
import Filters from '@containers/Shop/Filters/index'
import NotFound from '@pages/404/index'

import { selectFilter } from '@containers/Shop/Filters/selectors'
import { setLang, setUrl } from '@global/actions'

import '@scss/shop/index.scss'

const Shop = (props) => {
  const { filter, dispatch, lang, url } = props

  const [shopPage, setShopPage] = useState([])
  const [dynamicFilters, setDynamicFilters] = useState()

  const [loading, setLoading] = useState(true)
  const [hasError, setErrors] = useState(false)

  const [next, setNext] = useState(0)

  const postsPerPage = 9

  const location = useLocation()
  const t = useTranslation()

  const fetchFilters = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/filters`)
      const data = await res.json()
      setDynamicFilters(data)
    } catch (e) {
      setErrors(true)
      console.log(e)
    }
  }
  const fetchData = async (pointer = false, next = 0) => {
    const QUERY = `${process.env.REACT_APP_API_KEY}shop/products?lang=${lang}&start=${next}&amount=${postsPerPage}`

    const CATEGORIES = filter.categories.length > 0 ? `&category=${filter.categories.join(',')}` : ''
    const BRANDS = filter.brands.length > 0 ? `&brands=${filter.brands.join(',')}` : ''

    const api = `${QUERY}${CATEGORIES}${BRANDS}`

    try {
      const res = await fetch(api)
      const data = await res.json()
      if (pointer) {
        setShopPage(shopPage.concat(data.results))
        setLoading(false)
        return
      }
      setNext(0)
      setShopPage(data.results)
      setLoading(false)
    } catch (e) {
      setErrors(e)
      console.log(Error(e))
    }
  }

  const handleShowMorePosts = (pointer) => {
    const currentPosition = next + postsPerPage
    setNext(currentPosition)
    fetchData(pointer, currentPosition)
  }

  const callApi = async () => {
    await fetchFilters()
    await fetchData()
  }

  useEffect(() => {
    showLoader()
    callApi()
    dispatch(setUrl(url))
    dispatch(setLang(lang))
    setLanguage(lang)
  }, [filter, lang, location])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!shopPage && !loading) {
    return <NotFound />
  } else {
    return (
      <>
        <div className='page-content inner-pages shop-page'>
          <section id='first-screen' className='first-screen'>
            <div className='container title-place'>
              <div className='column-1-4'>
                <h1>{t('global.menu.catalog')}</h1>
              </div>
              <div className='column-3-4' />
            </div>
          </section>
          <section id='second-screen' className='second-screen'>
            <div className='container'>
              <div className='column-1-4'>
                <Filters dynamicFilters={dynamicFilters} />
              </div>
              <div className='column-3-4'>
                {shopPage.length > 0 ? (
                  <>
                    <div className='archive'>
                      {shopPage.length > 0 ? renderProducts(shopPage) : null}
                    </div>
                    {shopPage.length > 0 && (
                      <button
                        className='button load-more'
                        onClick={() => handleShowMorePosts(true)}
                      >
                        {t('pages.catalog.loadmore')}
                      </button>
                    )}
                  </>
                ) : (
                  <p>Ничего не найдено</p>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

Shop.defaultProps = {
  lang: 'ru',
  url: '/catalog'
}

Shop.propTypes = {
  dispatch: PropTypes.func,
  filter: PropTypes.any,
  lang: PropTypes.string,
  url: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  filter: selectFilter()
})

export default connect(mapStateToProps, null)(Shop)
