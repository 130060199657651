import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { setLang, setRegion, setUrl } from '@global/actions'
import { useTranslation, setLanguage } from 'react-multi-lang'

import { Link } from 'react-router-dom'

import { showLoader, hideLoader } from '@global/globalFunctions'

import NotFound from '@pages/404/index'

import '@scss/blog/index.scss'

const renderPosts = (data, url) => {
  const content = data.map((item, i) => (
    <Link key={`types${i}`} to={`${url}blog/post/${item.label}`} className='single-type'>
      <div className='number'>
        {`${i + 1}`}
      </div>
      <div className='single-type-image' style={{ backgroundImage: `url(${item.img})` }} />
      <div className='content'>
        <h4>{item.title}</h4>
        <div className='single-type-text' dangerouslySetInnerHTML={{ __html: item.first_text }} />
      </div>
    </Link>
  ))
  console.log(data)
  return content
}

const Blog = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [blogPage, setBlogPage] = useState()
  const [posts, setPosts] = useState()
  const [stateUrl, setStateUrl] = useState('')

  const [loading, setLoading] = useState(true)

  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}archives?lang=${lang}`)
    res.json()
      .then((res) => setBlogPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  async function fetchPosts () {
    // console.log(`${process.env.REACT_APP_API_KEY}posts?lang=${lang}`)
    const res = await fetch(`${process.env.REACT_APP_API_KEY}posts?lang=${lang}`)
    res.json()
      .then((res) => setPosts(res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()
    fetchPosts()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)

    if (region === 'ukraine') {
      switch (lang) {
        case 'ru':
          setStateUrl('/ru/')
          break
        case 'uk':
          setStateUrl('/')
          break
        case 'en':
          setStateUrl('/en/')
          break
        default:
          break
      }
    } else if (region === 'russia') {
      switch (lang) {
        case 'ru':
          setStateUrl('/russia/')
          break
        case 'uk':
          setStateUrl('/russia/ua/')
          break
        case 'en':
          setStateUrl('/russia/en/')
          break
        default:
          break
      }
    } else if (region === 'european') {
      switch (lang) {
        case 'ru':
          setStateUrl('/european/ru/')
          break
        case 'uk':
          setStateUrl('/european/ua/')
          break
        case 'en':
          setStateUrl('/european/')
          break
        default:
          break
      }
    }
  }, [lang, region])

  // console.log('blogPage', blogPage)
  // console.log('posts', posts)

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!blogPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages product-page blog-page'>
        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: blogPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: blogPage.first_text }} />
          </div>

          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h2>{t('pages.blog.news')}</h2>
              {posts ? renderPosts(posts, stateUrl) : null}
            </div>
          </div>
          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h2>{t('pages.blog.ourNews')}</h2>
              {posts ? renderPosts(posts, stateUrl) : null}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

Blog.defaultProps = {
  lang: 'ru',
  url: '/blog',
  region: 'ukraine'
}

Blog.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Blog)
