/**
 * Global selectors
 */

import { createSelector } from 'reselect'
import { initialState } from './userReducer'

const userState = (state) => state.global.user || initialState

const selectUrl = () => createSelector(userState,
  (pState) => pState.url)

const selectLang = () => createSelector(userState,
    (pState) => pState.lang)

const selectRegion = () => createSelector(userState,
    (pState) => pState.region)

const selectLocation = () => createSelector(userState,
   (pState) => pState.location)

const selectRedirect = () => createSelector(userState,
   (pState) => pState.redirect)

export {
  selectUrl,
  selectLang,
  selectRegion,
  selectLocation,
  selectRedirect
}
