import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Slider from 'react-slick'

import { Link } from 'react-router-dom'
import { withTranslation } from 'react-multi-lang'

class FirstScreen extends Component {
  constructor (props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next () {
    this.slider.slickNext()
  }

  previous () {
    this.slider.slickPrev()
  }

  renderSlides () {
    const { banners, t, currentUrl } = this.props
    const url = currentUrl.slice(0, -1)
    const content = banners.map((item, i) => (
      <div key={`MainBanner${i}`} className='single-slide'>
        <div className='slide-content' style={{ backgroundImage: `url(${item.img})` }}>
          <div className='container'>
            <div className='banner-content'>
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
              <Link to={`${url}${item.link}`} className='button'>
                {t('global.buttons.detail')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    ))

    return content
  }

  render () {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      appendDots: (dots) => (
        <div className='column-1-4'>
          <div className='navigation'>
            <button className='slick-prev' onClick={this.previous}>
              Previous
            </button>
            <ul style={{ margin: '0px' }}>
              {' '}
              {dots}
              {' '}
            </ul>
            <button className='slick-next' onClick={this.next}>
              Next
            </button>
          </div>

        </div>
      )
    }

    return (
      <section id='first-screen' className='first-screen container-full'>
        <div className='main-slider'>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.renderSlides()}
          </Slider>
        </div>
      </section>
    )
  }
}

FirstScreen.propTypes = {
  banners: PropTypes.array,
  t: PropTypes.any,
  currentUrl: PropTypes.string
}

export default withTranslation(FirstScreen)
