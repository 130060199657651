import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { hideLoader, renderDocuments, showLoader } from '@global/globalFunctions'
import Seo from '@components/Seo'

import NotFound from '@pages/404/index'

import '@scss/documents/index.scss'

const Documents = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [documentsPage, setDocumentsPage] = useState({
    documents: [],
    title: 'Document',
    first_text: '',
    guarantee_text: ''
  })

  const [loading, setLoading] = useState(true)

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}documents?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setDocumentsPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  // console.log('documentsPage', documentsPage)
  if (loading) {
    return null
  } else if (!documentsPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages document-page'>
        <Seo seo={{ title: documentsPage.seo_title, canonical: documentsPage.seo_canonical, description: documentsPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: documentsPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: documentsPage.first_text }} />
          </div>
          <div className='container certificates'>
            {renderDocuments(documentsPage.documents)}
          </div>
        </section>
      </div>
    )
  }
}

Documents.defaultProps = {
  lang: 'ru',
  url: '/info/documents',
  region: 'ukraine'
}

Documents.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Documents)
