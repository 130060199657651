/**
 * Filters selectors
 */

import { createSelector } from 'reselect'
import { initialState } from './reducer'

const filterState = (state) => state.shop.filter || initialState

const selectFilter = () => createSelector(filterState,
  (pState) => pState)

const selectCategoriesList = () => createSelector(filterState,
  (pState) => pState.categoriesList)

export {
  selectFilter,
  selectCategoriesList
}
