import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

export default class TeamSlider extends Component {
  constructor (props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      teams: props.teams
    }
  }

  componentDidMount () {
    const { teams } = this.props

    this.setState({
      teams: teams
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.teams !== prevProps.teams) {
      this.setState({
        teams: this.props.teams
      })
    }
  }

  next () {
    this.slider.slickNext()
  }

  previous () {
    this.slider.slickPrev()
  }

  renderTeam () {
    const { teams } = this.state

    const content = teams.map((item, i) => (
      <div key={'team' + i} className='single-team'>
        <img src={item.img} />
        <h5>{item.name}</h5>
        <p>{item.position}</p>
      </div>
    ))
    return content
  }

  render () {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      arrows: false,

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 569,
          settings: {
            slidesToShow: 3
          }
        }
      ]
      // autoplay: true,
    }

    return (
      <div className='team-slider'>
        <Slider ref={c => (this.slider = c)} {...settings} className='team'>
          {this.renderTeam()}
        </Slider>

        <div className='slider-navigation'>
          <button className='slick-prev' onClick={this.previous}>
            Previous
          </button>
          <button className='slick-next' onClick={this.next}>
            Next
          </button>
        </div>
      </div>
    )
  }
}

TeamSlider.propTypes = {
  teams: PropTypes.array
}
