import React, { useState } from 'react'

import PersenInfo from '@containers/Account/PersonInfo/index'
import Wishlist from '@containers/Account/Wishlist/index'
import ThirdStep from '@containers/Registration/ThirdStep/index'
import FourthStep from '@containers/Registration/FourthStep/index'

import '@scss/shop/index.scss'

function rendrSwitch (data) {
  switch (data) {
    case 'personInfo':
      return <PersenInfo />
    case 'wishlist':
      return <Wishlist />
    default:
      return null
  }
}

export default function Account () {
  const [active, setActive] = useState('personInfo')

  return (
    <div className='page-content inner-pages account-page'>
      <section id='first-screen' className='first-screen'>
        <div className='container'>
          <div className='column-1-4'>
            <h1>лИЧНЫЙ КАБИНЕТ</h1>
          </div>
          <div className='column-3-4' />
        </div>
        <div className='container'>
          <div className='column-1-4'>
            <ul>
              <li className={(active === 'personInfo') ? 'active-step' : null} onClick={() => setActive('personInfo')}>
                ЛИЧНАЯ ИНФОРМАЦИЯ
              </li>

              <li className={(active === 'cart') ? 'active-step' : null} onClick={() => setActive('cart')}>
                КОРЗИНА
              </li>

              <li className={(active === 'wishlist') ? 'active-step' : null} onClick={() => setActive('wishlist')}>
                ИЗБРАННЫЕ ТОВАРЫ
              </li>

              <li className={(active === 'history') ? 'active-step' : null} onClick={() => setActive('history')}>
                МОИ ЗАКАЗЫ
              </li>
            </ul>
          </div>
          <div className='column-3-4'>
            {rendrSwitch(active)}
          </div>
        </div>
      </section>
    </div>
  )
}
