import {
  SET_URL,
  SET_LANG,
  SET_REGION,
  SET_LOCATION,
  SET_REDIRECT
} from './constants'

/**
 * Set Url
 *
 * @param url string
 */

export function setUrl (url) {
  return {
    type: SET_URL,
    url
  }
}

/**
 * Set Lang
 *
 * @param lang string
 */

export function setLang (lang) {
  return {
    type: SET_LANG,
    lang
  }
}

/**
 * Set Region
 *
 * @param lang string
 */

export function setRegion (region) {
  return {
    type: SET_REGION,
    region
  }
}

/**
 * Set Location
 *
 * @param location string
 */

export function setLocation (location) {
  return {
    type: SET_LOCATION,
    location
  }
}

/**
 * Set Redirect
 *
 * @param redirect bool
 */

export function setRedirect (redirect) {
  return {
    type: SET_REDIRECT,
    redirect
  }
}
