import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang'
import ru from './translations/ru.json'
import uk from './translations/ua.json'
import en from './translations/en.json'

setDefaultTranslations({ ru, uk, en })
setDefaultLanguage('ru')

ReactDOM.render(
  <App />,
  document.getElementById('astex')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
