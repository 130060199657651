import React from 'react'

import { Link } from 'react-router-dom'
import ProductCard from '@components/ProductCard/index'
import ServicesFrom from '@containers/ServicesForm'

import Poster from '../../assets/img/poster.jpeg'
import { setRedirect } from '@global/actions'

export function textContent (data) {
  const content = data.map((item, i) => (item.file ? (
    <div key={`AboutCompany${i}`} className='container-full'>
      {item.file.slice(-3) === 'mp4' ? (
        <video controls='controls' poster={Poster}>
          <source src={item.file} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        </video>
      ) : <img className='img-full' src={item.file} alt={`About company ${i}`} />}
    </div>
  ) : (
    <div key={`AboutCompany${i}`} className='container'>
      <div className='column-1-4'>
        <p className='quote' dangerouslySetInnerHTML={{ __html: item.quote }} />
      </div>
      <div className='column-3-4' dangerouslySetInnerHTML={{ __html: item.text }} />
    </div>
  )))

  return content
}

export function serviceContent (data, title) {
  const content = data.map((item, i) => (item.file ? (
    <div key={`AboutCompany${i}`} className='container-full'>
      {item.file.slice(-3) === 'mp4' ? (
        <video controls='controls' poster={Poster}>
          <source src={item.file} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        </video>
      ) : <img className='img-full' src={item.file} alt={`About company ${i}`} />}
    </div>
  ) : (
    <div key={`AboutCompany${i}`} className='container'>
      <div className='column-1-4'>
        <h2 dangerouslySetInnerHTML={{ __html: item.quote_title }} />
        <p className='quote' dangerouslySetInnerHTML={{ __html: item.quote_text }} />

        <ServicesFrom title={title} />
      </div>
      <div className='column-3-4' dangerouslySetInnerHTML={{ __html: item.content }} />
    </div>
  )))

  return content
}

export function renderCards (data, btn, url, path) {
  const cardStyle = data.length === 3 ? 'ws-catalog' : ''
  const content = data.map((item, i) => (
    <div
      key={`serviceCard${i}`}
      className={(i % 2) !== 0 ? `${cardStyle} services-card light` : `${cardStyle} services-card dark`}
      style={{ backgroundImage: `url(${item.thumbnail})` }}
    >
      <div className='card-head'>
        <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
        <p>{item.short_about_type || item.short_about_type || null}</p>
      </div>

      <Link to={`${url}${path}${item.label}`} className='button'>
        {btn || 'подробнее'}
      </Link>
    </div>
  ))
  return content
}

export const renderCategoryCards = (data, btn, url) => {
  return data.map((item, i) => (
    <div key={`serviceCard${i}`} className={(i % 2) !== 0 ? 'services-card category-card light' : 'services-card category-card dark'} style={{ backgroundImage: `url(${item.picture})` }}>
      <div className='card-head'>
        <h2>{item.name}</h2>
        <p>{item.short_about_type || null}</p>
      </div>

      <Link to={`${url}wholesales-catalog/${item.label}`} className='button'>
        {btn || 'подробнее'}
      </Link>
    </div>
  ))
}

export const renderSubCategoryCards = (data, btn, url, path) => {
  const filteredData = data.filter(item => item.product_count !== 0)
  const getCardsCount = () => {
    console.log(data)
    const count = filteredData.length
    switch (count) {
      case 2:
        return 'sub-cat-two-cards'
      case 3:
        return 'sub-cat-three-cards'
      case 4:
        return 'sub-cat-four-cards'
      default:
        return 'sub-cat-two-cards'
    }
  }
  return filteredData.map((item, i) => (
    <div key={`serviceCard${i}`} className={(i % 2) !== 0 ? `${getCardsCount()} subcat services-card light` : `${getCardsCount()} subcat services-card dark`} style={{ backgroundImage: `url(${item.picture})` }}>
      <div className='card-head'>
        <h2>{item.name}</h2>
        <p>{item.short_about_type || null}</p>
      </div>

      <Link to={`${url}wholesales-catalog/${path}/${item.label}`} className='button'>
        {btn || 'подробнее'}
      </Link>
    </div>
  ))
}

export function renderServiceCards (data, btn, url) {
  const content = data.map((item, i) => (
    <div key={`serviceCard${i}`} className={(i % 2) !== 0 ? 'services-card light' : 'services-card dark'} style={{ backgroundImage: `url(${item.thumbnail})` }}>
      <div className='card-head'>
        <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
        <p>{item.short_about_type || item.short_about_type || null}</p>
      </div>

      <Link to={`${url}company/our-work/services/${item.label}`} className='button'>
        {btn || 'ПОДРОБНЕЕ'}
      </Link>
    </div>
  ))
  return content
}

export function renderDocuments (data) {
  const content = data.map((item, i) => (
    <Link key={`types${i}`} to={{ pathname: item.pdf }} className='single-certificate' target='_blank'>
      <img src={item.img} />
      <h4>{item.title}</h4>
      <p>{item.text}</p>
    </Link>
  ))

  return content
}

export function renderPartners (data) {
  const content = data.map((item, i) => (
    <Link key={item.img + i} to={{ pathname: item.link }} rel='noopener noreferrer' className='single-partner' target='_blank'>
      <img className='img' src={item.img} alt={`Partners logo${item.name}`} />
      <h5>{item.name}</h5>
    </Link>
  ))
  return content
}

export function renderProducts (data) {
  if (data.length === 0) {
    return <h2>Простите, ничего не найдено</h2>
  }
  const content = data.map((item, i) => (
    <ProductCard key={`${i}Products`} data={item} gallery={item.gallery[0]} />
  ))
  return content
}

export function renderPosts (data, stateUrl = '') {
  const content = data.map((item, i) => (
    <Link key={`types${i}`} to={`${stateUrl}blog/post/${item.label}`} className='single-type'>
      <div className='number'>
        0
        {i + 1}
      </div>
      <div className='content'>
        <h4>{item.title}</h4>
        <div className='post-content' dangerouslySetInnerHTML={{ __html: item.first_text }} />
      </div>
    </Link>
  ))

  return content
}

// Loader
export const loader = document.querySelector('.loader')

export const showLoader = () => loader.classList.remove('loader--hide')

export const hideLoader = () => loader.classList.add('loader--hide')

// call in useEffect into container/page component
export const setCurrentLang = (region, lang, setStateUrl) => {
  if (region === 'ukraine') {
    switch (lang) {
      case 'ru':
        setStateUrl('/ru/')
        break
      case 'uk':
        setStateUrl('/')
        break
      case 'en':
        setStateUrl('/en/')
        break
      default:
        break
    }
  } else if (region === 'russia') {
    switch (lang) {
      case 'ru':
        setStateUrl('/russia/')
        break
      case 'uk':
        setStateUrl('/russia/ua/')
        break
      case 'en':
        setStateUrl('/russia/en/')
        break
      default:
        break
    }
  } else if (region === 'european') {
    switch (lang) {
      case 'ru':
        setStateUrl('/european/ru/')
        break
      case 'uk':
        setStateUrl('/european/ua/')
        break
      case 'en':
        setStateUrl('/european/')
        break
      default:
        break
    }
  }
}

export const convLocationToRegion = (location, region) => {
  switch (region) {
    case 'ukraine':
      return 'Ukraine'
    case 'russia':
      return 'Russia'
    default:
      return 'Europe'
  }
}
