import React, { Component } from 'react'

export default class PersenInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit (event) {
    alert(`Сочинение отправлено: ${this.state.value}`)
    event.preventDefault()
  }

  render () {
    return (
      <div className='for-form'>
        <form onSubmit={this.handleSubmit}>
          <label>
            Фамилия
          </label>
          <input type='text' placeholder='Фамилия' value='Иванов Иван' />

          <label>
            Мобильный телефон
          </label>
          <input placeholder='Мобильный телефон' value='+38 (050) 000 00 00' />

          <label>
            Email
          </label>
          <input placeholder='Email' value='ivan.ivanov@mail.com' />

          <label>
            Должность
          </label>
          <input placeholder='Должность' value='Менеджер по закупкам' />

          <label>
            Адрес доставок по умолчанию:
          </label>
          <input placeholder='Адрес доставок по умолчанию:' value='г. Харьков, ул. Переяславская 23, офис 304' />

          <input className='button' type='submit' value='Изменить' />
        </form>
      </div>

    )
  }
}
