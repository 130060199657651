export const SET_CATEGORIES = 'app/Containers/Shop/Filters/SET_CATEGORIES'
export const SET_BRAND = 'app/Containers/Shop/Filters/SET_BRAND'
export const SET_MATERIALS = 'app/Containers/Shop/Filters/SET_MATERIALS'
export const SET_PRICE = 'app/Containers/Shop/Filters/SET_PRICE'
export const SET_COLOR = 'app/Containers/Shop/Filters/SET_COLOR'

export const REMOVE_CATEGORIES = 'app/Containers/Shop/Filters/REMOVE_CATEGORIES'
export const REMOVE_BRAND = 'app/Containers/Shop/Filters/REMOVE_BRAND'
export const REMOVE_MATERIALS = 'app/Containers/Shop/Filters/REMOVE_MATERIALS'
export const REMOVE_PRICE = 'app/Containers/Shop/Filters/REMOVE_PRICE'
export const REMOVE_COLOR = 'app/Containers/Shop/Filters/REMOVE_COLOR'
export const CLEAR_SELECTOR = 'app/Containers/Shop/Filters/CLEAR_SELECTOR'

export const SET_CATEGORIES_LIST = 'app/Containers/Shop/Filters/SET_CATEGORIES_LIST'
export const GET_CATEGORIES_LIST = 'app/Containers/Shop/Filters/GET_CATEGORIES_LIST'


