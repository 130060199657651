import React, { useState } from 'react'
import { TelegramClient } from 'messaging-api-telegram'

import { useTranslation } from 'react-multi-lang'

import SuccessSubmit from '@components/SuccessSubmit'
import telegram from '@global/telegram'

export default function VacancyFrom () {
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')
  const [link, setLink] = useState('')

  const [validName, setValidName] = useState(true)
  const [validPosition, setValidPosition] = useState(true)
  const [validMail, setValidMail] = useState(true)
  const [validLink, setValidLInk] = useState(true)

  const [submitStatus, setSubmitStatus] = useState(false)

  const t = useTranslation()

  const client = new TelegramClient({
    accessToken: process.env.REACT_APP_TELEGRAM_BOT
  })

  const nameChange = (e) => {
    setName(e.target.value)
    setValidName(true)
  }

  const positionChange = (e) => {
    setPosition(e.target.value)
    setValidPosition(true)
  }

  const mailChange = (e) => {
    setMail(e.target.value)
    setValidMail(true)
  }

  const messageChange = (e) => {
    setMessage(e.target.value)
  }

  const linkChange = (e) => {
    setLink(e.target.value)
    setValidLInk(true)
  }

  const validateField = (fieldName, value) => {
    // const {name, phone, email} = this.state
    let valid
    switch (fieldName) {
      case 'name':
        valid = value.length >= 1
        setValidName(valid)
        return valid
      case 'position':
        valid = value.length >= 3
        setValidPosition(valid)
        return valid
      case 'mail':
        valid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setValidMail(valid)
        return valid
      case 'link':
        valid = value.length >= 5
        setValidLInk(valid)
        return valid
      default:
        return null
    }
  }

  const thanksModal = () => {
    const content = submitStatus
      ? <SuccessSubmit setStatus={(status) => setSubmitStatus(status)} status={submitStatus}>
          <h2 dangerouslySetInnerHTML={{ __html: t('global.forms.tanksTitle') }} />
          <p>{t('global.forms.tanksText')}</p>
        </SuccessSubmit>
      : null
    return content
  }

  const refresh = () => {
    setName('')
    setPosition('')
    setLink('')
    setMessage('')
    setMail('')
  }

  const submit = (e) => {
    e.preventDefault()

    // alert(`Form data: ${data}`)
    // console.log('Form data: ', data)

    validateField('name', name)
    validateField('position', position)
    validateField('mail', mail)
    validateField('link', link)

    const ids = telegram()

    if (validateField('name', name) && validateField('position', position) && validateField('mail', mail) && validateField('link', link)) {
      ids.map((item) =>
        client.sendMessage(item, '<b>ВАКАНСИИ</b>\n\n' +
              `<b>Фамилия и имя: </b> ${name} \n` +
              `<b>Желаемая должность: </b> ${position} \n` +
              `<b>Email: </b> <a href="mailto:${mail}">${mail}</a> \n` +
              `<b>Сопроводительное письмо: </b> ${message} \n` +
              `<a href="${link}">Ссылка на резюме</a>\n`, {
          disableWebPagePreview: true,
          disableNotification: true,
          parseMode: 'HTML'
        })
      )
      setSubmitStatus(true)
      refresh()
    }
  }
  // console.log('Env', process.env.REACT_APP_API_KEY)

  return (
    <div>
      {thanksModal()}
      <form onSubmit={submit}>
        <label>{t('global.forms.nameLabel')}</label>
        <input className={validName ? null : 'not-valid'} value={name} onChange={nameChange} type='text' placeholder={t('global.forms.namePl')} />

        <label>{t('global.forms.positionLabel')}</label>
        <input className={validPosition ? null : 'not-valid'} value={position} onChange={positionChange} placeholder={t('global.forms.positionLabel')} />

        <label>{t('global.forms.emailLabel')}</label>
        <input className={validMail ? null : 'not-valid'} type='email' value={mail} onChange={mailChange} placeholder='ivan.ivanov@mail.com' />

        <label>{t('global.forms.messageLabel')}</label>
        <textarea value={message} onChange={messageChange} placeholder={t('global.forms.messagePl')} />

        <label>{t('global.forms.linkLabel')}</label>
        <input className={validLink ? null : 'not-valid'} value={link} onChange={linkChange} placeholder={t('global.forms.linkLabel')} />

        <button className='button' type='submit'>{t('global.buttons.send')}</button>
      </form>
    </div>
  )
}
