import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-multi-lang'

import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectUrl, selectLang, selectRegion } from '@global/selectors'

import { selectWorkTypes } from '@containers/Header/selectors'
import { getWorkTypes } from '@containers/Header/actions'
import { clearSelector, setCategories } from '@containers/Shop/Filters/actions'

import Cart from '@containers/Cart'
import useWindowSize from '@global/windowSize'
import MobileMenu from '@components/MobileMenu'
import RegionSelector from '@components/RegionSelector/RegionSelector'

import '@scss/global.scss'
import Logo from '@img/logo.svg'

const renderWorkCatalog = (data, url) => {
  const content = data.map((item, i) =>
    <li key={`workCatalog${i}`} className='title'>
      <Link to={`${url}company/our-work/${item.label}`}>{item.title}</Link>
    </li>
  )
  return content
}

const renderWorkCategories = (data, url, dispatch) => {
  const handleClick = (label) => {
    dispatch(clearSelector('categories'))
    dispatch(clearSelector('materials'))
    dispatch(clearSelector('brands'))
    dispatch(clearSelector('colors'))
    dispatch(setCategories(label))
  }
  const content = data.map((item, i) =>
    <li key={`workCategories${i}`}>
      <Link onClick={() => handleClick(item.label)} to={`${url}catalog`}>{item.title}</Link>
    </li>
  )
  return content
}

const renderWorkSite = (data, url) => {
  const content = data.slice(0, 3).map((item, i) =>
    <li key={`workCatalog${i}`}>
      <Link to={`${url}company/our-work/${item.label}`}>{item.title}</Link>
    </li>
  )
  return content
}

const renderCatalog = (data, url) => {
  return data.slice(0, 3).map((item, i) =>
    <li key={`workCatalog${i}`}>
      <Link to={`${url}wholesales-catalog/${item.label}`}>{item.title}</Link>
    </li>
  )
}

function useOutsideAlerter (ref, setCatalog) {
  useEffect(() => {
    /**
         * Alert if clicked on outside of element
         */
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setCatalog(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const Header = (props) => {
  const { dispatch, workTypes, url, lang, region } = props
  const [catalog, setCatalog] = useState(false)
  const [stateUrl, setStateUrl] = useState('')

  const setStyles = (attr, value) => region !== 'european' ? ({ [attr]: value }) : null

  const size = useWindowSize()
  const wrapperRef = useRef(null)
  const t = useTranslation()

  useEffect(() => {
    dispatch(getWorkTypes())

    if (region === 'ukraine') {
      switch (lang) {
        case 'ru':
          setStateUrl('/ru/')
          break
        case 'uk':
          setStateUrl('/')
          break
        case 'en':
          setStateUrl('/en/')
          break
        default:
          break
      }
    } else if (region === 'russia') {
      switch (lang) {
        case 'ru':
          setStateUrl('/russia/')
          break
        case 'uk':
          setStateUrl('/russia/ua/')
          break
        case 'en':
          setStateUrl('/russia/en/')
          break
        default:
          break
      }
    } else if (region === 'european') {
      switch (lang) {
        case 'ru':
          setStateUrl('/european/ru/')
          break
        case 'uk':
          setStateUrl('/european/ua/')
          break
        case 'en':
          setStateUrl('/european/')
          break
        default:
          break
      }
    }
  }, [lang, region])

  useOutsideAlerter(wrapperRef, setCatalog)

  // console.log('workTypes', workTypes)

  function renderLang () {
    if (region === 'ukraine') {
      switch (lang) {
        case 'ru':
          return (
            <li className='has-sub-menu'>
              <Link to={`/ru${url}`}>RU</Link>
              <div className='sub-menu'>
                <ul>
                  <li className='title'>
                    <Link to={`${url}`}>UA</Link>
                  </li>
                  <li className='title'>
                    <Link to={`/en${url}`}>EN</Link>
                  </li>
                </ul>
              </div>
            </li>)
        case 'en':
          return (
            <li className='has-sub-menu'>
              <Link to={`/en${url}`}>EN</Link>

              <div className='sub-menu'>
                <ul>
                  <li className='title'>
                    <Link to={`/ru${url}`}>RU</Link>
                  </li>
                  <li className='title'>
                    <Link to={`${url}`}>UA</Link>
                  </li>
                </ul>
              </div>
            </li>)
        case 'uk':
          return (
            <li className='has-sub-menu'>
              <Link to={`${url}`}>UA</Link>

              <div className='sub-menu'>
                <ul>
                  <li className='title'>
                    <Link to={`/ru${url}`}>RU</Link>
                  </li>
                  <li className='title'>
                    <Link to={`/en${url}`}>EN</Link>
                  </li>
                </ul>
              </div>
            </li>)
      }
    } else if (region === 'russia') {
      switch (lang) {
        case 'ru':
          return (
            <li className='has-sub-menu'>
              <Link to={`/russia${url}`}>RU</Link>

              <div className='sub-menu'>
                <ul>
                  {/* <li className='title'> */}
                  {/*  <Link to={`/russia/ua${url}`}>UA</Link> */}
                  {/* </li> */}
                  <li className='title'>
                    <Link to={`/russia/en${url}`}>EN</Link>
                  </li>
                </ul>
              </div>
            </li>)
        case 'en':
          return (
            <li className='has-sub-menu'>
              <Link to={`/russia/en${url}`}>EN</Link>

              <div className='sub-menu'>
                <ul>
                  <li className='title'>
                    <Link to={`/russia${url}`}>RU</Link>
                  </li>
                  {/* <li className='title'> */}
                  {/*  <Link to={`/russia/ua${url}`}>UA</Link> */}
                  {/* </li> */}
                </ul>
              </div>
            </li>)
        case 'uk':
          return (
            <li className='has-sub-menu'>
              <Link to={`/russia/ua${url}`}>UA</Link>

              <div className='sub-menu'>
                <ul>
                  <li className='title'>
                    <Link to={`/russia${url}`}>RU</Link>
                  </li>
                  <li className='title'>
                    <Link to={`/russia/en${url}`}>EN</Link>
                  </li>
                </ul>
              </div>
            </li>)
      }
    } else if (region === 'european') {
      return (
        <li className='has-sub-menu'>
          <Link to={`/european${url}`}>EN</Link>
        </li>
      )
    }
  }

  // console.log(lang, region, stateUrl)

  return (
    <header className='container-full'>
      <div className='top'>
        {size.width > 1000 ? (
          <div className='container'>
            <div className='left'>
              {region === 'ukraine' && (
                <div ref={wrapperRef} className='catalog-menu' onClick={() => setCatalog(true)}>
                  <i className='icon-catalog-menu' />
                  <h4>{t('global.menu.catalog')}</h4>
                  <div className={catalog ? 'sub-menu-place active' : 'sub-menu-place'}>
                    <div className='sub-menu category-menu '>
                      {workTypes ? <div className='container'>
                        <div className='column-1-4'>
                          <ul>
                            {renderWorkCatalog(workTypes, stateUrl)}
                          </ul>
                        </div>
                        <div className='column-1-4'>
                          <ul>
                            <li className='title'>
                              <Link to={`${stateUrl}company/our-work/${workTypes[1].label}`}>{workTypes[1].title}</Link>
                            </li>
                            {renderWorkCategories(workTypes[1].category, stateUrl, dispatch)}
                          </ul>
                        </div>
                        <div className='column-1-4'>
                          <ul>
                            <li className='title'>
                              <Link to={`${stateUrl}company/our-work/${workTypes[2].label}`}>{workTypes[2].title}</Link>
                            </li>
                            {renderWorkCategories(workTypes[2].category, stateUrl, dispatch)}
                          </ul>
                        </div>
                        <div className='column-1-4'>
                          <ul>
                            <li className='title'>
                              <Link to={`${stateUrl}company/our-work/${workTypes[0].label}`}>{workTypes[0].title}</Link>
                            </li>
                            {renderWorkCategories(workTypes[0].category, stateUrl, dispatch)}
                            <li className='title'>
                              <Link to={`${stateUrl}company/our-work/${workTypes[3].label}`}>{workTypes[3].title}</Link>
                            </li>
                          </ul>
                        </div>
                      </div> : null}
                    </div>
                  </div>
                </div>
              )}
              <Link className='catalog-menu ws-catalog-menu' style={region === 'european' ? setStyles('marginLeft', '0') : null} to={`${stateUrl}wholesales-catalog`}>
                <h4>{t('global.menu.wp_catalog')}</h4>
              </Link>
            </div>
            <div className='center'>
              <Link to={stateUrl}>
                <img src={Logo} alt='Astext logo' />
              </Link>
            </div>
            <div className='right'>
              <div className='search'>
                <i className='icon-search' />
              </div>
              {region === 'ukraine' && (
                <>
                  <div className='favorite'>
                    <i className='icon-favorite' />
                  </div>
                  <Cart />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className='left'>
              <MobileMenu />

              <Link to={stateUrl} className='logo-place'>
                <img src={Logo} alt='Astext logo' />
              </Link>
            </div>
            <div className='center'>
              <Link to={`${stateUrl}`}>
                <img src={Logo} alt='Astext logo' />
              </Link>
            </div>
            <div className='right'>
              <div className='search'>
                <i className='icon-search' />
              </div>
              {region !== 'european' && (
                <>
                  <div className='favorite'>
                    <i className='icon-favorite' />
                  </div>
                  <div className='cart'>
                    <i className='icon-cart' />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='bottom'>
        {size.width > 1000 ? (
          <div className='container'>
            <div className='left'>
              <ul>
                <li className='has-sub-menu'>
                  <Link to={`${stateUrl}company`}>
                    {t('global.menu.company')}
                  </Link>

                  <div className='sub-menu'>
                    <ul>
                      <li className='title'>
                        <Link to={`${stateUrl}company`}>{t('global.menu.aboutCompany')}</Link>
                      </li>
                      <li>
                        <Link to={`${stateUrl}company/office`}>{t('global.menu.team')}</Link>
                      </li>
                      <li>
                        <Link to={`${stateUrl}company/partners`}>{t('global.menu.partners')}</Link>
                      </li>
                      <li>
                        <Link to={`${stateUrl}company/sales-policy`}>{t('global.menu.policy')}</Link>
                      </li>
                      {region === 'ukraine' ? (
                        <>
                          <li className='title'>
                            <Link to={`${stateUrl}company/our-work`}>{t('global.menu.types')}</Link>
                          </li>
                          {workTypes ? renderWorkSite(workTypes, stateUrl) : null}
                        </>
                      ) : (
                        <>
                          <li className='title'>
                            <Link to={`${stateUrl}wholesales-catalog`}>{t('global.menu.types')}</Link>
                          </li>
                          {workTypes ? renderCatalog(workTypes, stateUrl) : null}
                        </>
                      )}
                      {region === 'ukraine' && (
                        <li>
                          <Link to={`${stateUrl}company/our-work/services`}>{t('global.menu.services')}</Link>
                        </li>
                      )}
                      <li className='title'>
                        <Link to={`${stateUrl}company/vacancies`}>{t('global.menu.vacancies')}</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className='has-sub-menu'>
                  <a>{t('global.menu.usefullInfo')}</a>

                  <div className='sub-menu'>
                    <ul>
                      <li className='title'>
                        {region === 'ukraine' && <Link to={`${stateUrl}catalog`}>{t('global.menu.catalogP')}</Link>}
                      </li>
                      <li className='title'>
                        <Link to={`${stateUrl}wholesales-catalog`}>{t('global.menu.catalogO')}</Link>
                      </li>
                      <li className='title'>
                        <Link to={`${stateUrl}info/certificates`}>{t('global.menu.certificates')}</Link>
                      </li>
                      <li className='title'>
                        <Link to={`${stateUrl}info/documents`}>{t('global.menu.documents')}</Link>
                      </li>
                      <li className='title'>
                        <Link to={`${stateUrl}info/faq`}>{t('global.menu.faq')}</Link>
                      </li>
                    </ul>
                  </div>

                </li>

                <li>
                  <Link to={`${stateUrl}blog`}>
                    {t('global.menu.blog')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className='right'>
              <ul>
                <RegionSelector />
                {renderLang()}

                <li className='has-sub-menu'>
                  <Link to={`${stateUrl}contact/main-office`}>
                    {t('global.menu.contact')}
                  </Link>

                  <div className='sub-menu'>
                    <ul>
                      <li className='title'>
                        <Link to={`${stateUrl}contact/main-office`}>{t('global.menu.office')}</Link>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <hr /> */}
                {/* <li className='has-sub-menu'> */}
                {/*  <Link to='/'> */}
                {/*    Для бизнес-клиентов */}
                {/*  </Link> */}

                {/*  <div className='sub-menu'> */}
                {/*    <ul> */}
                {/*      <li className='title'> */}
                {/*        <Link to='/account'>Личный кабинет</Link> */}
                {/*      </li> */}
                {/*      <li className='title'> */}
                {/*        <Link to='/registration'>Стать бизнес клиентом</Link> */}
                {/*      </li> */}
                {/*    </ul> */}
                {/*  </div> */}
                {/* </li> */}
              </ul>
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className='center' style={region === 'european' ? setStyles('justifyContent', 'center') : null}>
              {region === 'ukraine' && (
                <Link className='catalog-menu' onClick={() => setCatalog(true)} to={`${stateUrl}catalog`}>
                  <i className='icon-catalog-menu' />
                  <h4>{t('global.menu.catalog')}</h4>
                </Link>
              )}
              <Link className='catalog-menu ws-catalog-menu' to={`${stateUrl}wholesales-catalog`}>
                <h4>{t('global.menu.wp_catalog')}</h4>
              </Link>
            </div>
          </div>
        )}
      </div>

    </header>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func,
  workTypes: PropTypes.any,
  url: PropTypes.string,
  lang: PropTypes.string,
  region: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  workTypes: selectWorkTypes(),
  url: selectUrl(),
  lang: selectLang(),
  region: selectRegion()
})

export default connect(mapStateToProps, null)(Header)
