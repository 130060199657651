import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Switch,
  Route, Redirect
} from 'react-router-dom'

import ScrollToTop from '@global/ScrollToTop'
// eslint-disable-next-line import/no-unresolved
import Header from '@components/../containers/Header/index'
import Footer from '@components/../containers/Footer/index'
import Socials from '@components/Socials/index'

import Home from '@pages/Home'
import WorkTypes from '@pages/WorkTypes'
import Services from '@pages/Services'
import SingleServices from '@pages/SingleServices'
import SingleWorkType from '@pages/SingleWorkType'

import AboutCompany from '@pages/AboutCompany'
import Team from '@pages/Team'
import SalesPolicy from '@pages/SalesPolicy'
import Partners from '@pages/Partners'
import Faq from '@pages/Faq'
import Certificate from '@pages/Certificate'
import Documents from '@pages/Documents'
import Vacancies from '@pages/Vacancies'

import Contact from '@pages/Contact'

import Shop from '@pages/Shop/index'
import Product from '@pages/Product/index'

import Registration from '@pages/Registration/index'
import Account from '@pages/Account/index'

import Blog from '@pages/Blog/index'
import BlogPost from '@pages/BlogPost/index'

import Ordering from '@pages/Ordering'

import NotFound from '@pages/404'
import ServerError from '@pages/500'

import WholesaleCatalog from '@pages/WholesaleCatalog'
import WholesaleProductPage from '@pages/WholesaleProductPage/index'
import WholesaleCategory from '@pages/WholesaleCategory'

import Geolocation from '@global/geoloacation'
import WholesaleSubCategory from "@pages/WholesaleSubCategory";

export default function Router () {
  const [redirects, setRedirects] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}redirects`)
    res.json()
      .then((res) => setRedirects(res))
      .catch((err) => console.log(err))
  }

  const renderRoutes = () => {
    const content = redirects.map((item, i) =>
      <Route key={`redirect${i}`} exact path={item.from_address}>
        <Redirect to={item.to_address} />
      </Route>
    )

    return content
  }

  return (
    <BrowserRouter>
      <Geolocation />
      <ScrollToTop>
        <Header />
        <Socials />
        <Switch>

          {renderRoutes()}

          {/* Home page Ukarine */}
          <Route exact path='/'>
            <Home lang='uk' />
          </Route>
          <Route exact path='/ru'>
            <Home lang='ru' />
          </Route>
          <Route exact path='/en'>
            <Home lang='en' />
          </Route>

          {/* Home page Russia */}
          <Route exact path='/russia/'>
            <Home lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua'>
            <Home lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en'>
            <Home lang='en' region='russia' />
          </Route>

          {/* Home page European */}
          <Route exact path='/european/'>
            <Home lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua'>
            <Home lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru'>
            <Home lang='en' region='european' />
          </Route>

          <Route exact path='/ordering'>
            <Ordering />
          </Route>

          {/* About company page Ukarine */}
          <Route exact path='/company'>
            <AboutCompany lang='uk' />
          </Route>
          <Route exact path='/ru/company'>
            <AboutCompany lang='ru' />
          </Route>
          <Route exact path='/en/company'>
            <AboutCompany lang='en' />
          </Route>

          {/* About company page Russia */}
          <Route exact path='/russia/company'>
            <AboutCompany lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company'>
            <AboutCompany lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company'>
            <AboutCompany lang='en' region='russia' />
          </Route>

          {/* About company page European */}
          <Route exact path='/european/company'>
            <AboutCompany lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company'>
            <AboutCompany lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company'>
            <AboutCompany lang='en' region='european' />
          </Route>

          {/* Team page Ukarine */}
          <Route exact path='/company/office'>
            <Team lang='uk' />
          </Route>
          <Route exact path='/ru/company/office'>
            <Team lang='ru' />
          </Route>
          <Route exact path='/en/company/office'>
            <Team lang='en' />
          </Route>

          {/* Team page Russia */}
          <Route exact path='/russia/company/office'>
            <Team lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/office'>
            <Team lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/office'>
            <Team lang='en' region='russia' />
          </Route>

          {/* Team page European */}
          <Route exact path='/european/company/office'>
            <Team lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/office'>
            <Team lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/office'>
            <Team lang='en' region='european' />
          </Route>

          {/* Sales policy page Ukarine */}
          <Route exact path='/company/sales-policy'>
            <SalesPolicy lang='uk' />
          </Route>
          <Route exact path='/ru/company/sales-policy'>
            <SalesPolicy lang='ru' />
          </Route>
          <Route exact path='/en/company/sales-policy'>
            <SalesPolicy lang='en' />
          </Route>

          {/* Sales policy page Russia */}
          <Route exact path='/russia/company/sales-policy'>
            <SalesPolicy lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/sales-policy'>
            <SalesPolicy lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/sales-policy'>
            <SalesPolicy lang='en' region='russia' />
          </Route>

          {/* Sales policy page European */}
          <Route exact path='/european/company/sales-policy'>
            <SalesPolicy lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/sales-policy'>
            <SalesPolicy lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/sales-policy'>
            <SalesPolicy lang='en' region='european' />
          </Route>

          {/* Partners page Ukarine */}
          <Route exact path='/company/partners'>
            <Partners lang='uk' />
          </Route>
          <Route exact path='/ru/company/partners'>
            <Partners lang='ru' />
          </Route>
          <Route exact path='/en/company/partners'>
            <Partners lang='en' />
          </Route>

          {/* Partners page Russia */}
          <Route exact path='/russia/company/partners'>
            <Partners lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/partners'>
            <Partners lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/partners'>
            <Partners lang='en' region='russia' />
          </Route>

          {/* Partners page European */}
          <Route exact path='/european/company/partners'>
            <Partners lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/partners'>
            <Partners lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/partners'>
            <Partners lang='ru' region='european' />
          </Route>

          {/* Vacancies page Ukarine */}
          <Route exact path='/company/vacancies'>
            <Vacancies lang='uk' />
          </Route>
          <Route exact path='/ru/company/vacancies'>
            <Vacancies lang='ru' />
          </Route>
          <Route exact path='/en/company/vacancies'>
            <Vacancies lang='en' />
          </Route>

          {/* Vacancies page Russia */}
          <Route exact path='/russia/company/vacancies'>
            <Vacancies lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/vacancies'>
            <Vacancies lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/vacancies'>
            <Vacancies lang='en' region='russia' />
          </Route>

          {/* Vacancies page European */}
          <Route exact path='/european/company/vacancies'>
            <Vacancies lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/vacancies'>
            <Vacancies lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/vacancies'>
            <Vacancies lang='en' region='european' />
          </Route>

          {/* WorkTypes page Ukarine */}
          <Route exact path='/company/our-work'>
            <WorkTypes lang='uk' />
          </Route>
          <Route exact path='/ru/company/our-work'>
            <WorkTypes lang='ru' />
          </Route>
          <Route exact path='/en/company/our-work'>
            <WorkTypes lang='en' />
          </Route>

          {/* WorkTypes page Russia */}
          <Route exact path='/russia/company/our-work'>
            <WorkTypes lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/our-work'>
            <WorkTypes lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/our-work'>
            <WorkTypes lang='en' region='russia' />
          </Route>

          {/* WorkTypes page European */}
          <Route exact path='/european/company/our-work'>
            <WorkTypes lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/our-work'>
            <WorkTypes lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/our-work'>
            <WorkTypes lang='en' region='european' />
          </Route>

          {/* Services page Ukarine */}
          <Route exact path='/company/our-work/services'>
            <Services lang='uk' />
          </Route>
          <Route exact path='/ru/company/our-work/services'>
            <Services lang='ru' />
          </Route>
          <Route exact path='/en/company/our-work/services'>
            <Services lang='en' />
          </Route>

          {/* Services page Russia */}
          <Route exact path='/russia/company/our-work/services'>
            <Services lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/our-work/services'>
            <Services lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/our-work/services'>
            <Services lang='en' region='russia' />
          </Route>

          {/* Services page European */}
          <Route exact path='/european/company/our-work/services'>
            <Services lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/our-work/services'>
            <Services lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/our-work/services'>
            <Services lang='en' region='european' />
          </Route>

          {/* Single service page Ukarine */}
          <Route exact path='/company/our-work/services/:label'>
            <SingleServices lang='uk' />
          </Route>
          <Route exact path='/ru/company/our-work/services/:label'>
            <SingleServices lang='ru' />
          </Route>
          <Route exact path='/en/company/our-work/services/:label'>
            <SingleServices lang='en' />
          </Route>

          {/* Single service page Russia */}
          <Route exact path='/russia/company/our-work/services/:label'>
            <SingleServices lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/our-work/services/:label'>
            <SingleServices lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/our-work/services/:label'>
            <SingleServices lang='en' region='russia' />
          </Route>

          {/* Single service page European */}
          <Route exact path='/european/company/our-work/services/:label'>
            <SingleServices lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/our-work/services/:label'>
            <SingleServices lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/our-work/services/:label'>
            <SingleServices lang='en' region='european' />
          </Route>

          {/* Single WorkType page Ukarine */}
          <Route exact path='/company/our-work/:label'>
            <SingleWorkType lang='uk' />
          </Route>
          <Route exact path='/ru/company/our-work/:label'>
            <SingleWorkType lang='ru' />
          </Route>
          <Route exact path='/en/company/our-work/:label'>
            <SingleWorkType lang='en' />
          </Route>

          {/* Single WorkType page Russia */}
          <Route exact path='/russia/company/our-work/:label'>
            <SingleWorkType lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/company/our-work/:label'>
            <SingleWorkType lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/company/our-work/:label'>
            <SingleWorkType lang='en' region='russia' />
          </Route>

          {/* Single WorkType page European */}
          <Route exact path='/european/company/our-work/:label'>
            <SingleWorkType lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/company/our-work/:label'>
            <SingleWorkType lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/company/our-work/:label'>
            <SingleWorkType lang='en' region='european' />
          </Route>

          {/* Faq page Ukarine */}
          <Route exact path='/info/faq'>
            <Faq lang='uk' />
          </Route>
          <Route exact path='/ru/info/faq'>
            <Faq lang='ru' />
          </Route>
          <Route exact path='/en/info/faq'>
            <Faq lang='en' />
          </Route>

          {/* Faq page Russia */}
          <Route exact path='/russia/info/faq'>
            <Faq lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/info/faq'>
            <Faq lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/info/faq'>
            <Faq lang='en' region='russia' />
          </Route>

          {/* Faq page European */}
          <Route exact path='/european/info/faq'>
            <Faq lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/info/faq'>
            <Faq lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/info/faq'>
            <Faq lang='en' region='european' />
          </Route>

          {/* Certificate page Ukarine */}
          <Route exact path='/info/certificates'>
            <Certificate lang='uk' />
          </Route>
          <Route exact path='/ru/info/certificates'>
            <Certificate lang='ru' />
          </Route>
          <Route exact path='/en/info/certificates'>
            <Certificate lang='en' />
          </Route>

          {/* Certificate page Russia */}
          <Route exact path='/russia/info/certificates'>
            <Certificate lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/info/certificates'>
            <Certificate lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/info/certificates'>
            <Certificate lang='en' region='russia' />
          </Route>

          {/* Certificate page European */}
          <Route exact path='/european/info/certificates'>
            <Certificate lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/info/certificates'>
            <Certificate lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/info/certificates'>
            <Certificate lang='en' region='european' />
          </Route>

          {/* Documents page Ukarine */}
          <Route exact path='/info/documents'>
            <Documents lang='uk' />
          </Route>
          <Route exact path='/ru/info/documents'>
            <Documents lang='ru' />
          </Route>
          <Route exact path='/en/info/documents'>
            <Documents lang='en' />
          </Route>

          {/* Documents page Russia */}
          <Route exact path='/russia/info/documents'>
            <Documents lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/info/documents'>
            <Documents lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/info/documents'>
            <Documents lang='en' region='russia' />
          </Route>

          {/* Documents page European */}
          <Route exact path='/european/info/documents'>
            <Documents lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/info/documents'>
            <Documents lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/info/documents'>
            <Documents lang='en' region='european' />
          </Route>

          {/* Contact page Ukarine */}
          <Route exact path='/contact/main-office'>
            <Contact lang='uk' />
          </Route>
          <Route exact path='/ru/contact/main-office'>
            <Contact lang='ru' />
          </Route>
          <Route exact path='/en/contact/main-office'>
            <Contact lang='en' />
          </Route>

          {/* Contact page Russia */}
          <Route exact path='/russia/contact/main-office'>
            <Contact lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/contact/main-office'>
            <Contact lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/contact/main-office'>
            <Contact lang='en' region='russia' />
          </Route>

          {/* Contact page Europe */}
          <Route exact path='/european/contact/main-office'>
            <Contact region='european' lang='en' />
          </Route>
          <Route exact path='/european/ua/contact/main-office'>
            <Contact lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/contact/main-office'>
            <Contact region='european' lang='ru' />
          </Route>

          <Route exact path='/ru/catalog'>
            <Shop lang='ru' />
          </Route>
          <Route exact path='/catalog'>
            <Shop lang='uk' />
          </Route>
          <Route exact path='/en/catalog'>
            <Shop lang='en' />
          </Route>

          <Route exact path='/catalog/products/:id'>
            <Product lang='uk' />
          </Route>
          <Route exact path='/ru/catalog/products/:id'>
            <Product lang='ru' />
          </Route>
          <Route exact path='/en/catalog/products/:id'>
            <Product lang='en' />
          </Route>

          <Route exact path='/catalog/:category'>
            <Shop lang='uk' />
          </Route>
          <Route exact path='/ru/catalog/:category'>
            <Shop lang='ru' />
          </Route>
          <Route exact path='/en/catalog/:category'>
            <Shop lang='en' />
          </Route>

          <Route exact path='/registration'>
            <Registration />
          </Route>
          <Route exact path='/account'>
            <Account />
          </Route>

          {/* Blog page Ukraine */}
          <Route exact path='/blog'>
            <Blog lang='uk' />
          </Route>
          <Route exact path='/ru/blog'>
            <Blog lang='ru' />
          </Route>
          <Route exact path='/en/blog'>
            <Blog lang='en' />
          </Route>

          {/* Blog page Russia */}
          <Route exact path='/russia/blog'>
            <Blog lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/blog'>
            <Blog lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/blog'>
            <Blog lang='en' region='russia' />
          </Route>

          {/* Blog page European */}
          <Route exact path='/european/blog'>
            <Blog lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/blog'>
            <Blog lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/blog'>
            <Blog lang='ru' region='european' />
          </Route>

          {/* Blog post page Ukraine */}
          <Route exact path='/blog/post/:label'>
            <BlogPost lang='uk' />
          </Route>
          <Route exact path='/ru/blog/post/:label'>
            <BlogPost lang='ru' />
          </Route>
          <Route exact path='/en/blog/post/:label'>
            <BlogPost lang='en' />
          </Route>

          {/* Blog post page Russia */}
          <Route exact path='/russia/blog/post/:label'>
            <BlogPost lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/blog/post/:label'>
            <BlogPost lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/blog/post/:label'>
            <BlogPost lang='en' region='russia' />
          </Route>

          {/* Blog post page European */}
          <Route exact path='/european/blog/post/:label'>
            <BlogPost lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/blog/post/:label'>
            <BlogPost lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/blog/post/:label'>
            <BlogPost lang='ru' region='european' />
          </Route>

          {/* Wholesale Category page Ukarine */}
          <Route exact path='/wholesales-catalog'>
            <WholesaleCategory lang='uk' />
          </Route>
          <Route exact path='/ru/wholesales-catalog'>
            <WholesaleCategory lang='ru' />
          </Route>
          <Route exact path='/en/wholesales-catalog'>
            <WholesaleCategory lang='en' />
          </Route>

          {/* Wholesale Category page Russia */}
          <Route exact path='/russia/wholesales-catalog'>
            <WholesaleCategory lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/wholesales-catalog'>
            <WholesaleCategory lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/wholesales-catalog'>
            <WholesaleCategory lang='en' region='russia' />
          </Route>

          {/* Wholesale Category page European */}
          <Route exact path='/european/wholesales-catalog'>
            <WholesaleCategory lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/wholesales-catalog'>
            <WholesaleCategory lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/wholesales-catalog'>
            <WholesaleCategory lang='en' region='european' />
          </Route>

          {/* Wholesale Sub-Category page Ukarine */}
          <Route exact path='/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='uk' />
          </Route>
          <Route exact path='/ru/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='ru' />
          </Route>
          <Route exact path='/en/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='en' />
          </Route>

          {/* Wholesale Sub-Category page Russia */}
          <Route exact path='/russia/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='en' region='russia' />
          </Route>

          {/* Wholesale Sub-Category page European */}
          <Route exact path='/european/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/wholesales-catalog/:label'>
            <WholesaleSubCategory lang='en' region='european' />
          </Route>

          {/* Wholesales catalog page Ukraine */}
          <Route exact path='/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='uk' />
          </Route>
          <Route exact path='/ru/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='ru' />
          </Route>
          <Route exact path='/en/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='en' />
          </Route>

          {/* Wholesales catalog page european */}
          <Route exact path='/european/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='ru' region='european' />
          </Route>

          {/* Wholesales catalog page Russia */}
          <Route exact path='/russia/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/wholesales-catalog/:category/:label'>
            <WholesaleCatalog lang='en' region='russia' />
          </Route>

          {/* Wholesales product card page Ukraine */}
          <Route exact path='/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='uk' />
          </Route>
          <Route exact path='/ru/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='ru' />
          </Route>
          <Route exact path='/en/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='en' />
          </Route>

          {/* Wholesales product card page Russia */}
          <Route exact path='/russia/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='ru' region='russia' />
          </Route>
          <Route exact path='/russia/ua/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='uk' region='russia' />
          </Route>
          <Route exact path='/russia/en/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='en' region='russia' />
          </Route>

          {/* Wholesales product card page european */}
          <Route exact path='/european/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='en' region='european' />
          </Route>
          <Route exact path='/european/ua/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='uk' region='european' />
          </Route>
          <Route exact path='/european/ru/wholesales-catalog/:category/:label/:product'>
            <WholesaleProductPage lang='ru' region='european' />
          </Route>

          <Route path='*'>
            <NotFound />
          </Route>

        </Switch>
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  )
}
