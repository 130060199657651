import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { setLanguage } from 'react-multi-lang'
import { setUrl, setLang, setRegion } from '@global/actions'

import FirstScreen from '@components/home/FirstScreen/index'
import SecondScreen from '@components/home/SecondScreen/index'
import ThirdScreen from '@components/home/ThirdScreen/index'
import FourthScreen from '@components/home/FourthScreen/index'
import FifthScreen from '@components/home/FifthScreen/index'

import Seo from '@components/Seo'

import NotFound from '@pages/404/index'

import {showLoader, hideLoader, setCurrentLang} from '@global/globalFunctions'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import '@scss/home/index.scss'

const Home = (props) => {
  const { lang, url, dispatch, region } = props
  const [hasError, setErrors] = useState(false)
  const [homePage, setHomePage] = useState()
  const [loading, setLoading] = useState(true)
  const [stateUrl, setStateUrl] = useState()
  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}home_page?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setHomePage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  // console.log('Home', homePage)

  useEffect(() => {
    dispatch(setUrl(url))
  }, [])

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))
    setCurrentLang(region, lang, setStateUrl)
    setLanguage(lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!homePage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content home-page'>
        <Seo seo={{ title: homePage.seo_title, canonical: homePage.seo_canonical, description: homePage.seo_description }} />

        <FirstScreen currentUrl={stateUrl} banners={homePage.main_banners} />

        {region === 'ukraine' ? <SecondScreen products={homePage.products} /> : <br />}

        <ThirdScreen first_about_company={homePage.first_about_company} first_quote={homePage.first_quote} />
        <FourthScreen />
        <FifthScreen second_about_company={homePage.second_about_company} />
      </div>
    )
  }
}

Home.defaultProps = {
  lang: 'ru',
  url: '/',
  region: 'ukraine'
}

Home.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Home)
