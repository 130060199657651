import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-multi-lang'

import { createStructuredSelector } from 'reselect'
import { selectCart } from './selectors'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'
import Modal from 'react-modal'

import CartItem from '@containers/Cart/CartItem'

import '@scss/cart/index.scss'

const customStyles = {
  content: {
    top: '0',
    left: 'auto',
    right: '0',
    bottom: 'auto'
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)'
  }
}

const renderCarItem = (data) => {
  const content = data.map((item, i) =>
    <CartItem key={`cartProductItem${i}`} product={item} />
  )

  return content
}

const Cart = (props) => {
  const { cart } = props
  const [modalIsOpen, setIsOpen] = useState(false)

  const t = useTranslation()

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  //console.log('Cart', cart)

  return (
    <div className='cart'>
      <div onClick={openModal}>
        <i className='icon-cart' />
        <span>
          {t('global.menu.cart')}:
          <span className='cost'>{cart.total}</span>
          {' '}
          {t('global.cart.currency')}
        </span>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Cart Modal'
      >
        <div className='modal-content cartModal'>
          <div className='modal-close' onClick={closeModal}>+</div>
          <div className='modal-content-top'>
            <h3>{t('global.menu.cart')}</h3>
          </div>
          <div className='modal-content-center'>
            <div className='modal-container'>
              {cart.products.length > 0
                ? <div className='cartHead'>
                  <span>{t('global.cart.products')}</span>
                  <span />
                  <span />
                  <span>{t('global.cart.numbers')}</span>
                  <span>{t('global.cart.cost')}</span>
                  <span />
                  <hr />
                  </div>
                : null}

              <div className='cartBody'>
                {cart.products.length > 0 ? renderCarItem(cart.products) : null}
              </div>
            </div>
          </div>
          <div className='modal-content-bottom'>
            <div className='left'>
              <Link to='/catalog' onClick={closeModal}>{t('global.buttons.continua')}</Link>
            </div>
            <div className='right'>

              <div className='total'>
                <span>{t('global.cart.all')}</span> <span>{cart.total} {t('global.cart.currency')}</span>
              </div>

              {cart.products.length > 0
                ? <Link to='/ordering' onClick={closeModal} className='button'>{t('global.buttons.order')}</Link>
                : <Link to='/' onClick={(e) => e.preventDefault()} className='button disable'>{t('global.buttons.order')}</Link>}

            </div>
          </div>
        </div>
      </Modal>
    </div>

  )
}

Cart.propTypes = {
  dispatch: PropTypes.func,
  cart: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  cart: selectCart()
})

export default connect(mapStateToProps, null)(Cart)
