import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLocation, setRedirect } from '@global/actions'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-multi-lang'

import { LOCAL_USER_GEO } from '@global/constants'
import { convLocationToRegion } from '@global/globalFunctions'

import './style.scss'

const SelectRegionModal = () => {
  const [isActive, setIsActive] = useState(true)
  const [selectedRegion, setSelectedRegion] = useState('Ukraine')
  const { redirect, region, location } = useSelector(({ global: { user } }) => ({ ...user }))

  const t = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClick = (event) => {
    const country = event.target.id
    if (event.target.id === 'Ukraine') {
      history.push('/')
      dispatch(setRedirect(true))
      dispatch(setLocation(country))
      localStorage.setItem(LOCAL_USER_GEO, country)
    } else if (event.target.id === 'Russia') {
      history.push('/russia')
      dispatch(setRedirect(true))
      dispatch(setLocation(country))
      localStorage.setItem(LOCAL_USER_GEO, country)
    } else {
      history.push('/european')
      dispatch(setRedirect(true))
      dispatch(setLocation(country))
      localStorage.setItem(LOCAL_USER_GEO, country)
    }
  }

  const currentLocation = () => {
    switch (region) {
      case 'ukraine':
        return t('global.menu.regions.ukraine')
      case 'russia':
        return t('global.menu.regions.russia')
      case 'european':
        return t('global.menu.regions.europe')
      default:
        t('global.menu.regions.ukraine')
    }
  }

  const defaultId = () => {
    switch (region) {
      case 'ukraine':
        console.log('ukraine')
        setSelectedRegion('Ukraine')
        break
      case 'russia':
        console.log('Russia')
        setSelectedRegion('Russia')
        break
      case 'european':
        console.log('Europe')
        setSelectedRegion('Europe')
        break
      default: setSelectedRegion('Ukraine')
    }
  }

  useEffect(() => {
    defaultId()
  }, [region])

  useEffect(() => {
    if (location) {
      const regionLocation = convLocationToRegion(location, region)
      if (regionLocation !== location) {
        setRedirect(false)
        setIsActive(true)
        return
      }
    }
    if (redirect) {
      setIsActive(false)
    }
  }, [redirect, location, isActive, region])

  return (
    <div className={isActive ? 'region-modal active' : 'region-modal'}>
      <div className='modal-content'>
        <h1 className='title'>{t('global.modals.place')}</h1>
        <h1 className='title region'>{currentLocation()}</h1>
        <div className='wrapper'>
          <button onClick={handleClick} id={selectedRegion} className='btn'>{t('global.modals.yes-button')}</button>
          <div className='dropdown '>
            <p>{t('global.modals.region')}</p>
            <ul className='list'>
              {/*<li onClick={handleClick} id='Russia' className='list-item'>{t('global.menu.regions.russia')}</li>*/}
              <li onClick={handleClick} id='Ukraine' className='list-item'>{t('global.menu.regions.ukraine')}</li>
              <li onClick={handleClick} id='Europe' className='list-item'>{t('global.menu.regions.europe')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectRegionModal
