import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { renderServiceCards, showLoader, hideLoader } from '@global/globalFunctions'
import Seo from '@components/Seo'

import NotFound from '@pages/404/index'

import '@scss/service/index.scss'

const Services = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [servicesPage, setServicesPage] = useState({
    title: '',
    first_text: '',
    second_text: ''
  })
  const [services, setServices] = useState([])
  const [stateUrl, setStateUrl] = useState('')

  const [loading, setLoading] = useState(true)
  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}service_page?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setServicesPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  async function fetchServices () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}services?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setServices(res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()
    fetchServices()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)

    switch (lang) {
      case 'ru':
        setStateUrl('/ru/')
        break
      case 'uk':
        setStateUrl('/')
        break
      case 'en':
        setStateUrl('/en/')
        break
      default:
        break
    }
  }, [lang, region])

  // console.log('servicesPage', servicesPage)
  // console.log('services', services)

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!servicesPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages services-page'>
        <Seo seo={{ title: services.seo_title, canonical: services.seo_canonical, description: services.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: servicesPage.title }} />
            </div>
            <div className='column-3-4 sub-title-text' dangerouslySetInnerHTML={{ __html: servicesPage.first_text }} />

          </div>
        </section>

        <section id='second-screen' className='second-screen'>
          <div className='container-full'>
            {services ? renderServiceCards(services, t('global.buttons.detail'), stateUrl) : null}
          </div>
        </section>

      </div>
    )
  }
}

Services.defaultProps = {
  lang: 'ru',
  url: '/company/our-work/services',
  region: 'ukraine'
}

Services.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Services)
