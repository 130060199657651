import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { withTranslation } from 'react-multi-lang'

import Slider from 'react-slick'

import { renderProducts } from '@global/globalFunctions'

class ProductSlider extends Component {
  constructor (props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      products: []
    }
  }

  componentDidMount () {
    const { lang } = this.props
    fetch(`${process.env.REACT_APP_API_KEY}shop/products?lang=${lang}&start=0&amount=9`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.results.length > 0) {
            this.setState({
              products: result.results
            })
          }
          // console.log('SEO', result)
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }

  next () {
    this.slider.slickNext()
  }

  previous () {
    this.slider.slickPrev()
  }

  render () {
    const { t } = this.props
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      appendDots: (dots) => (
        <div className='column-1-4'>
          <h2>{t('global.components.newProductsTitle')}</h2>
          <p>{t('global.components.newProductsText')}</p>
          <div className='navigation'>
            <button className='slick-prev' onClick={this.previous}>
              Previous
            </button>
            <ul style={{ margin: '0px' }}>
              {' '}
              {dots}
              {' '}
            </ul>
            <button className='slick-next' onClick={this.next}>
              Next
            </button>
          </div>
          <Link to='/catalog' className='button'>
            {t('global.buttons.allNews')}
          </Link>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    return (
      <section id='second-screen' className='second-screen'>
        <div className='container'>
          {/* eslint-disable-next-line no-return-assign,react/jsx-props-no-spreading */}
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {renderProducts(this.state.products)}
          </Slider>
        </div>
      </section>
    )
  }
}

ProductSlider.propTypes = {
  lang: PropTypes.string,
  t: PropTypes.any
}

export default withTranslation(ProductSlider)
