import React, { useState } from 'react'
import { TelegramClient } from 'messaging-api-telegram'

import { useTranslation } from 'react-multi-lang'

import SuccessSubmit from '@components/SuccessSubmit'
import telegram from '@global/telegram'

export default function PartnerFrom ({ region = 'ukraine' }) {
  const [name, setName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')

  const [validName, setValidName] = useState(true)
  const [validCompanyName, setValidCompanyName] = useState(true)
  const [validPhone, setValidPhone] = useState(true)
  const [validMail, setValidMail] = useState(true)

  const [submitStatus, setSubmitStatus] = useState(false)

  const t = useTranslation()

  const client = new TelegramClient({
    accessToken: process.env.REACT_APP_TELEGRAM_BOT
  })

  const nameChange = (e) => {
    setName(e.target.value)
    setValidName(true)
  }

  const companyNameChange = (e) => {
    setCompanyName(e.target.value)
    setValidCompanyName(true)
  }

  const phoneChange = (e) => {
    setPhone(e.target.value)
    setValidPhone(true)
  }

  const mailChange = (e) => {
    setMail(e.target.value)
    setValidMail(true)
  }

  const messageChange = (e) => {
    setMessage(e.target.value)
  }

  const validateField = (fieldName, value) => {
    // const {name, phone, email} = this.state
    let valid
    switch (fieldName) {
      case 'name':
        valid = value.length >= 1
        setValidName(valid)
        return valid
      case 'companyName':
        valid = value.length >= 1
        setValidCompanyName(valid)
        return valid
      case 'phone':
        // valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        valid = true
        setValidPhone(valid)
        return valid
      case 'mail':
        valid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setValidMail(valid)
        return valid
      default:
        return null
    }
  }

  const thanksModal = () => {
    const content = submitStatus
      ? <SuccessSubmit setStatus={(status) => setSubmitStatus(status)} status={submitStatus}>
          <h2 dangerouslySetInnerHTML={{ __html: t('global.forms.tanksTitle') }} />
          <p>{t('global.forms.tanksText')}</p>
        </SuccessSubmit>
      : null
    return content
  }

  const refresh = () => {
    setName('')
    setCompanyName('')
    setPhone('')
    setMessage('')
    setMail('')
  }

  const submit = (e) => {
    e.preventDefault()

    // alert(`Form data: ${data}`)
    // console.log('Form data: ', data)

    validateField('name', name)
    validateField('companyName', companyName)
    validateField('mail', mail)
    validateField('phone', phone)

    const ids = telegram()

    if (validateField('name', name) && validateField('companyName', companyName) && validateField('mail', mail) && validateField('phone', phone)) {
      ids.map((item) =>
        client.sendMessage(item,
          '<b>ПАРНЕРЫ</b>\n\n' +
              `<b>Фамилия и имя: </b> ${name} \n` +
              `<b>Название компании: </b> ${companyName} \n` +
              `<b>Контактный номер: </b> <a href="tel:${phone}">${phone}</a> \n` +
              `<b>Email: </b> <a href="mailto:${mail}">${mail}</a> \n` +
              `<b>Сопроводительное письмо: </b> ${message} \n`,
          {
            disableWebPagePreview: true,
            disableNotification: true,
            parseMode: 'HTML'
          })
      )
      setSubmitStatus(true)
      refresh()
    }
  }

  return (
    <div>
      {thanksModal()}
      <form onSubmit={submit}>
        <label>{t('global.forms.nameLabel')}</label>
        <input
          className={validName ? null : 'not-valid'} value={name} onChange={nameChange} type='text'
          placeholder={t('global.forms.namePl')}
        />

        <label>{t('global.forms.companyLabel')}</label>
        <input
          className={validCompanyName ? null : 'not-valid'} value={companyName}
          onChange={companyNameChange} placeholder={t('global.forms.companyPl')}
        />

        <label>{t('global.forms.phoneLabel')}</label>
        <input
          type='phone' className={validPhone ? null : 'not-valid'} value={phone} onChange={phoneChange}
          placeholder={region === 'russia' ? '+7 - XXX - XXX - XX - XX' : '+38 ХХХ - ХХХ - ХХ - ХХ'}
        />

        <label>{t('global.forms.emailLabel')}</label>
        <input
          className={validMail ? null : 'not-valid'} type='email' value={mail} onChange={mailChange}
          placeholder='ivan.ivanov@mail.com'
        />

        <label>{t('global.forms.messageLabel')}</label>
        <textarea value={message} onChange={messageChange} placeholder={t('global.forms.messagePl')} />

        <button className='button' type='submit'>{t('global.buttons.send')}</button>
      </form>
    </div>
  )
}
