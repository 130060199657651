import React, { useEffect, useState } from 'react'
import { renderProducts } from '@global/globalFunctions'

export default function Wishlist () {
  const [hasError, setErrors] = useState(false)
  const [wishlist, setWishlist] = useState()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/products?lang=ru`)
    res.json()
      .then((res) => setWishlist(res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    fetchData()
  }, [])

  // console.log('productPage', wishlist)

  return (

    <div className='wishlist'>
      {wishlist ? renderProducts(wishlist.slice(0, 3)) : null}
    </div>
  )
}
