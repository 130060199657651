import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setRegion, setUrl } from '@global/actions'

import { Link } from 'react-router-dom'

import FormVacancy from '@containers/FormVacancy/index'

import { showLoader, hideLoader } from '@global/globalFunctions'

import NotFound from '@pages/404/index'

import '@scss/vacancies/index.scss'
import '@scss/inner-pages/index.scss'

import Photo from '@img/vacancy.png'
import Seo from '@components/Seo'

function renderVacancies (data) {
  const content = data.map((item, i) => (
    <Link key={`types${i}`} to={{ pathname: item.link }} className='single-type' target='_blank'>
      <div className='number'>
        0
        {i + 1}
      </div>
      <div className='content'>
        <h4>{item.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    </Link>
  ))

  return content
}

const Vacancies = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [vacanciesPage, setVacanciesPage] = useState()

  const [loading, setLoading] = useState(true)
  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}vacancies?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setVacanciesPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    dispatch(setUrl(url))
  }, [])

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang])

  // console.log('vacancyPage', vacanciesPage)

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!vacanciesPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages vacancies-page'>
        <Seo seo={{ title: vacanciesPage.seo_title, canonical: vacanciesPage.seo_canonical, description: vacanciesPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: vacanciesPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: vacanciesPage.first_text }} />
          </div>
          <div className='container'>
            <div className='column-1-4' />
            {vacanciesPage.vacancies.length === 0
              ? (
                <div className='column-3-4 vacancies-place'>
                  <h2>{t('pages.vacancies.empty')}</h2>
                </div>
              )
              : (
                <div className='column-3-4 vacancies-place'>
                  <h2>{t('pages.vacancies.title')}</h2>
                  {renderVacancies(vacanciesPage.vacancies)}
                </div>
              )}
          </div>
          <div className='container-full'>
            <img className='img-full' src={Photo} alt='Vacancies page' />
          </div>
          <div className='container for-form'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h3>{t('pages.vacancies.formTitle')}</h3>
              <p>{t('pages.vacancies.formText')}</p>
              <FormVacancy />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

Vacancies.defaultProps = {
  lang: 'uk',
  url: '/company/vacancies',
  region: 'ukraine'
}

Vacancies.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Vacancies)
