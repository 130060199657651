import thunk from 'redux-thunk'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import cartReducer from '@containers/Cart/reducer'
import workTypesReducer from '@containers/Header/reducer'
import filterReducer from '@containers/Shop/Filters/reducer'
import globalReducer from '@global/userReducer'

import saga from '@containers/Header/saga'
import sagaCategories from '@containers/Shop/Filters/saga'

const sagaMiddleware = createSagaMiddleware()

const middleware = [thunk, sagaMiddleware]

const appReducer = combineReducers({
  global: combineReducers({
    user: globalReducer
  }),
  site: combineReducers({
    workTypes: workTypesReducer
  }),
  shop: combineReducers({
    filter: filterReducer,
    cart: cartReducer
  })
})

const store = createStore(appReducer, composeWithDevTools(
  applyMiddleware(...middleware)
))

store.runSaga = sagaMiddleware.run

store.runSaga(saga)
store.runSaga(sagaCategories)

export { store }
