import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectWorkTypes } from '@containers/Header/selectors'

import {renderCategoryCards, showLoader, hideLoader, setCurrentLang} from '@global/globalFunctions'

import NotFound from '@pages/404/index'

import '@scss/work/index.scss'
import { setLang, setUrl } from '@global/actions'

const WholesaleCategory = (props) => {
  const { dispatch, lang, url, region } = props
  const [hasError, setErrors] = useState(false)
  const [stateUrl, setStateUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [cardsData, setCardsData] = useState(null)

  const t = useTranslation()

  const fetchCardsData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/category_list/?lang=${lang}&region=${region}`)
      const data = await res.json()
      setCardsData(data)
      setLoading(false)
    } catch (e) {
      setErrors(true)
      console.log(e)
    }
  }

  useEffect(() => {
    dispatch(setUrl(url))
  }, [])

  useEffect(() => {
    showLoader()
    fetchCardsData()
    dispatch(setLang(lang))
    setLanguage(lang)

    setCurrentLang(region, lang, setStateUrl)
  }, [lang])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  // console.log('Work types', workTypes)
  if (loading) {
    return null
  } else if (!cardsData && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages work-types-page'>
        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 >{t('pages.wp_categories.title')}</h1>
            </div>
          </div>
        </section>

        <section id='second-screen' className='second-screen'>
          <div className='container-full'>
            {cardsData ? renderCategoryCards(cardsData, t('global.buttons.detail'), stateUrl) : null}
          </div>
        </section>

        {/*<section id='third-screen' className='third-screen'>*/}
        {/*  <div className='container'>*/}
        {/*    <div className='column-1-4' />*/}
        {/*  </div>*/}
        {/*</section>*/}
      </div>
    )
  }
}

WholesaleCategory.defaultProps = {
  lang: 'ru',
  url: '/wholesales-catalog/',
  region: 'ukraine'
}

WholesaleCategory.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  workTypes: selectWorkTypes()
})

export default connect(mapStateToProps, null)(WholesaleCategory)
