import produce from 'immer'
import {
  LOGOUT,
  SET_AUTH_TOKEN,
  SET_URL, SET_LANG,
  SET_REGION,
  SET_LOCATION,
  SET_REDIRECT
} from './constants'

// The initial state of the User
export const initialState = {
  // authToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjhlZGU4Y2I5ZWZlY2JiNThkZTI5MGYwZTNkZmZhNDNmYjFjNDAwZTA3OGM4Y2VmZmVkNzgzY2E0MDkxY2NlYTRmNzg5ODY3MTVkOTZiOGMyIn0.eyJhdWQiOiIyIiwianRpIjoiOGVkZThjYjllZmVjYmI1OGRlMjkwZjBlM2RmZmE0M2ZiMWM0MDBlMDc4YzhjZWZmZWQ3ODNjYTQwOTFjY2VhNGY3ODk4NjcxNWQ5NmI4YzIiLCJpYXQiOjE1OTQ2NjU0MzksIm5iZiI6MTU5NDY2NTQzOSwiZXhwIjoxNjI2MjAxNDM5LCJzdWIiOiI1MSIsInNjb3BlcyI6W119.B7iuWgIgYTjL278ws2TTrKew1guthX1CycauLOIeWtSzfRXiszLhKAoGmtItMo7D_Uiq_m3b29PBQ_lWLDMmZ-VkE2oEmSLenOpFVkmfWnFzp1ZmEwXq_sbOnciAju4XU6DtFMT-3psLsV29FzBxS-Y-DGaM4y_Y75lsNXP-84LpSP_F2JdpqwUn_wK3VcGZQWoF12uuQfYhQ0uQOgASjX5rwoT0wRLB8WmiedYCS7G2Xg7Hs1UPlEiZZnxgqV8vR3rQcoFbbNifLe-4lLUIqfoUIq0xer4OgAzOf3iqnbekq7mxyq2_HxppQyFuuhGaVNi2GQ340Kxcas3-yxe--ho6FO39LN1vc63lGLgUE-mDG3UGf2kPupYfSBnl9SokuvH0hG3BRlYuK2KuJe5scooov2dAaxQbfpV3l0IxXxeU6mly_kyoSE-LG-RviKpICbUbo-XndcWzJSj8-nqEAtYn_7xuPgyTl2t9hyocOGfQjQxZ4yYpz7X5rONuIOaa7Spt5kpya8XAPCAB-4NCkkyZEFmok2GLOVJMKk-J-PL5gdh8COFQHKrTpHHfZrrfqHL69A3Vv1BWjdokXfr1ZH5mLt1MTc9Jae-EmXO384FA9LcSEfnAIKEq7xzyxgm6ejMW4tAnhgFspKHj5sKaNr2jiSncjJRPqq2fLdxQv08',
  authToken: null,
  loading: false,
  error: null,
  url: '',
  lang: 'ru',
  region: 'ukraine',
  location: null,
  redirect: false,
  data: []
}

const userReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      draft.authToken = action.token
      break
    case SET_URL:
      draft.url = action.url
      break
    case SET_LANG:
      draft.lang = action.lang
      break
    case SET_REGION:
      draft.region = action.region
      break
    case SET_LOCATION:
      draft.location = action.location
      break
    case SET_REDIRECT:
      draft.redirect = action.redirect
      break
      // case LOGOUT:
      //     draft.authToken = null;
      //     break;
    default:
      break
  }
})

export default userReducer
