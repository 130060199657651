import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'
import { selectLocation, selectRedirect } from '@global/selectors'
import { setLocation, setRedirect } from '@global/actions'
import { LOCAL_USER_GEO } from "@global/constants";

import Geocode from 'react-geocode'

const Geolocation = (props) => {
  const { redirect, location, dispatch } = props
  const [stateLocation, setStateLocation] = useState(null)

  const history = useHistory()

  Geocode.setApiKey('AIzaSyDDA89OMshK64DO2uR6am_yStqO639dwPE')
  Geocode.setLanguage('en')
  Geocode.setLocationType('ROOFTOP')

  const setLocalStorLocation = () => {
    const localData = localStorage.getItem(LOCAL_USER_GEO)

    if (localData) {
      dispatch(setLocation(localData))
      dispatch(setRedirect(true))
      return
    }
    getCountry()
  }

  const getCountry = () => {
    if (stateLocation) {
      Geocode.fromLatLng(stateLocation.lat, stateLocation.long).then(
        (response) => {
          const address = response.results[0].formatted_address
          let city, state, country
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case 'locality':
                  city = response.results[0].address_components[i].long_name
                  break
                case 'administrative_area_level_1':
                  state = response.results[0].address_components[i].long_name
                  break
                case 'country':
                  country = response.results[0].address_components[i].long_name
                  break
              }
            }
          }
          dispatch(setLocation(country))
          localStorage.setItem(LOCAL_USER_GEO, country)
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setStateLocation({
        lat: position.coords.latitude,
        long: position.coords.longitude
      })
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
    })
  }, [])

  useEffect(() => {
    setLocalStorLocation()
    if (!redirect && location) {
      if (location === 'Ukraine') {
        history.push('/')
        dispatch(setRedirect(true))
      } else if (location === 'Russia') {
        history.push('/russia')
        dispatch(setRedirect(true))
      } else {
        history.push('/european')
        dispatch(setRedirect(true))
      }
    }
  }, [stateLocation, location])

  return null
}

Geolocation.propTypes = {
  location: PropTypes.string,
  redirect: PropTypes.bool,
  dispatch: PropTypes.func
}

const mapStateToProps = createStructuredSelector({
  location: selectLocation(),
  redirect: selectRedirect()
})

export default connect(mapStateToProps, null)(Geolocation)
