import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import FaqFrom from '@containers/FormFaq'
import Accordion from '@components/Accordion/index'
import Seo from '@components/Seo'

import { showLoader, hideLoader } from '@global/globalFunctions'

import NotFound from '@pages/404/index'

import '@scss/inner-pages/index.scss'

const renderFaqs = (data) => {
  const content = data.map((item, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Accordion key={`faq${i}`} data={item} index={i} />
  ))

  return content
}

const Faq = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [faqPage, setFaqPage] = useState()

  const [loading, setLoading] = useState(true)

  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}faqs?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setFaqPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!faqPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages faq-page'>
        <Seo seo={{ title: faqPage.seo_title, canonical: faqPage.seo_canonical, description: faqPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: faqPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: faqPage.first_text }} />
          </div>
          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <div className='partners'>
                {renderFaqs(faqPage.faqs)}
              </div>
            </div>
          </div>
          <div className='container for-form'>
            <div className='column-1-4' />

            <div className='column-3-4'>
              <h3>{t('pages.faq.formTitle')}</h3>
              <p>{t('pages.faq.formText')}</p>
              <FaqFrom />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

Faq.defaultProps = {
  lang: 'ru',
  url: '/info/faq',
  region: 'ukraine'
}

Faq.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Faq)
