import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import WSColorSelector from '@components/ColorSelector/WSColorSelector'

const WSColorPicker = ({ data, setImage }) => {
  const [nowSelected, setNowActive] = useState(data[0].color.color_name)
  const [currentLink, setCurrentLink] = useState(data[0].url)

  const selectHandler = (event) => {
    setNowActive(event.target.closest('div').id)
  }

  const spawnColors = () => {
    return data.map(({ color: { color, color_name }, photo, url }) => {
      return (
        <WSColorSelector
          key={color}
          bg={color}
          text={color_name}
          action={selectHandler}
          isSelected={nowSelected}
          setImage={setImage}
          photo={photo}
          url={url}
          setLink={setCurrentLink}
        />
      )
    })
  }

  return (
    <>
      <div className='colors-wrapper'>
        {spawnColors()}
      </div>
      {currentLink && <Link className='button' to={currentLink}>В Магазин</Link>}
    </>
  )
}

export default WSColorPicker
