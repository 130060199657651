import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

import { createStructuredSelector } from 'reselect'
import {connect, useDispatch, useSelector} from 'react-redux'

import { selectFilter, selectCategoriesList } from './selectors'
import {
  setBrands,
  setCategories,
  setMaterials,
  removeBrands,
  removeCategories,
  removeMaterials,
  getCategoriesList
} from './actions'

import CheckBox from '@containers/Shop/Filters/CheckBox'
import CtlgColorPicker from '@containers/ColorPicker/Ctlg_colorpicker'
import {useTranslation} from "react-multi-lang";

// function Price () {
//   const [value, setValue] = React.useState([10, 50])
//   return (
//     <div className='price-slider'>
//       <div className='price-input'>
//         <span>от</span>
//         <input
//           type='number'
//           min={0}
//           max={100}
//           value={value[0]}
//           onChange={(nextValue) => {
//             const [start, end] = value
//             if (nextValue > end) {
//               return
//             }
//             setValue([nextValue, end])
//           }}
//         />
//
//         <span>до</span>
//         <input
//           type='number'
//           value={value[1]}
//           min={0}
//           max={100}
//           onChange={(nextValue) => {
//             const [start, end] = value
//             if (start > nextValue) {
//               return
//             }
//             setValue([start, nextValue])
//           }}
//         />
//
//       </div>
//       <RangeSlider
//         progress
//         style={{ marginTop: 16 }}
//         value={value}
//         onChange={value => {
//           setValue(value)
//         }}
//       />
//     </div>
//   )
// }

const renderCategories = (data) => {
  const content = data.map((item) => {})

  return content
}

const Filters = (props) => {
  const { dynamicFilters } = props
  const { categories, brands, materials } = useSelector(({ shop: { filter: { categories, brands, materials } } }) => ({ categories, brands, materials }))
  const dispatch = useDispatch()
  const t = useTranslation()
  useEffect(() => {
    dispatch(getCategoriesList())
  }, [])

  const toggleCategories = (label, bool) => {
    if (bool) {
      dispatch(removeCategories(label))
    } else {
      dispatch(setCategories(label))
    }
  }

  const toggleMaterials = (label, bool) => {
    if (bool) {
      dispatch(removeMaterials(label))
    } else {
      dispatch(setMaterials(label))
    }
  }

  const toggleBrands = (label, bool) => {
    if (bool) {
      dispatch(removeBrands(label))
    } else {
      dispatch(setBrands(label))
    }
  }

  // render filters from resp
  const findAvailableFilters = (value) => {
    if (dynamicFilters) {
      return dynamicFilters.some(item => item.label === value)
    }
  }

  return (
    <div className='filters'>
      <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a', 'b', 'c', 'd']}>
        <AccordionItem uuid='a'>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className='filter-title'>
                {t('pages.catalog.categoryType')}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className='filter-content'>
              <CheckBox label='furnitura' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.doors.title')}</CheckBox>
              <div className='sub-check'>
                <CheckBox label='dvernie-petli' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.doors.doorHignes')}</CheckBox>
                <CheckBox label='balkonni-garnitur' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.doors.balconyHeadsets')}</CheckBox>
                <CheckBox label='dvernoy-garnitur' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.doors.doorHeadsets')}</CheckBox>
                <CheckBox label='ruchki-offisnie' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.doors.officeDoorknobs')}</CheckBox>
              </div>

              <CheckBox label='furnitura-dlya-okon' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.windows.title')}</CheckBox>
              <div className='sub-check'>
                <CheckBox label='ruchki-okonnie' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.windows.windowHandle')}</CheckBox>
                <CheckBox label='ruchki-okonnie-s-kluchem' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.windows.windowKeyHandle')}</CheckBox>
              </div>

              <CheckBox label='accesorise' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.accessories.title')}</CheckBox>
              <div className='sub-check'>
                <CheckBox label='dovodchiki' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.accessories.closer')}</CheckBox>
                <CheckBox label='accesoari-dlya-okon' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.accessories.windAccessories')}</CheckBox>
                <CheckBox label='accesoari-dlya-dverey' click={toggleCategories} selector={categories}>{t('pages.catalog.filters.accessories.doorAccessories')}</CheckBox>

              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        {/*<AccordionItem uuid='b'>*/}
        {/*  <AccordionItemHeading>*/}
        {/*    <AccordionItemButton>*/}
        {/*      <div className='filter-title'>*/}
        {/*        Материал*/}
        {/*      </div>*/}
        {/*    </AccordionItemButton>*/}
        {/*  </AccordionItemHeading>*/}
        {/*  <AccordionItemPanel>*/}
        {/*    <div className='filter-content'>*/}
        {/*      <CheckBox label='metal' click={toggleMaterials} selector={materials}>Металл</CheckBox>*/}
        {/*      <CheckBox label='alum-zink' click={toggleMaterials} selector={materials}>Алюминиевый и цинковый сплав</CheckBox>*/}
        {/*      <CheckBox label='metal-plac' click={toggleMaterials} selector={materials}>Метал / Пластик</CheckBox>*/}
        {/*    </div>*/}
        {/*  </AccordionItemPanel>*/}
        {/*</AccordionItem>*/}
        {/*{findAvailableFilters('color') && (*/}
        {/*  <AccordionItem uuid='c'>*/}
        {/*    <AccordionItemHeading>*/}
        {/*      <AccordionItemButton>*/}
        {/*        <div className='filter-title'>*/}
        {/*          Цвет*/}
        {/*        </div>*/}
        {/*      </AccordionItemButton>*/}
        {/*    </AccordionItemHeading>*/}
        {/*    <AccordionItemPanel>*/}
        {/*      <div className='filter-content'>*/}
        {/*        <CtlgColorPicker />*/}
        {/*      </div>*/}
        {/*    </AccordionItemPanel>*/}
        {/*  </AccordionItem>*/}
        {/*)}*/}
        {findAvailableFilters('brand') && (
          <AccordionItem uuid='d'>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className='filter-title'>
                  Торговая марка
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className='filter-content'>
                <CheckBox label='astex' click={toggleBrands} selector={brands}>ASTEX</CheckBox>
                <CheckBox label='frd' click={toggleBrands} selector={brands}>FRD</CheckBox>
                <CheckBox label='riko' click={toggleBrands} selector={brands}>Riko</CheckBox>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        )}

      </Accordion>
    </div>
  )
}

Filters.propTypes = {
  dynamicFilters: PropTypes.array
}

export default Filters
