import {
  ADD_TO_CART,
  CHANGE_CART,
  REFRESH_CART
} from './constants'

/**
 * Add to Cart
 *
 * @param product object
 */

export function addToCart (product) {
  //console.log('Add', product)

  return {
    type: ADD_TO_CART,
    product
  }
}

/**
 * Plus to Cart
 *
 * @param product object
 */

export function plusToCart (product, count) {
  //console.log('Plus', product)
  return {
    type: CHANGE_CART,
    product,
    count
  }
}

/**
 * Refrash Cart
 *
 * @param
 */

export function refreshCart () {
  return {
    type: REFRESH_CART
  }
}
