import React, { useEffect, useState } from 'react'
import { useTranslation, setLanguage } from 'react-multi-lang'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { renderSubCategoryCards, showLoader, hideLoader, setCurrentLang } from '@global/globalFunctions'
import { setLang, setUrl } from '@global/actions'

import NotFound from '@pages/404/index'

import '@scss/work/index.scss'

const WholesaleSubCategory = ({ lang, region }) => {
  const { url } = useSelector(({ global: { user } }) => ({ ...user }))

  const [hasError, setErrors] = useState(false)
  const [stateUrl, setStateUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [cardsData, setCardsData] = useState(null)

  const dispatch = useDispatch()
  const params = useParams()
  const t = useTranslation()

  const fetchCardsData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/category_list/?lang=${lang}&region=${region}&label=${params.label}`)
      const data = await res.json()
      setCardsData(data[0].children)
      setLoading(false)
    } catch (e) {
      setErrors(true)
      console.log(e)
    }
  }

  useEffect(() => {
    dispatch(setUrl(`${url}${params.label}`))
  }, [])

  useEffect(() => {
    showLoader()
    fetchCardsData()
    dispatch(setLang(lang))
    setLanguage(lang)

    setCurrentLang(region, lang, setStateUrl)
  }, [lang, params.label])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!cardsData && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages work-types-page'>
        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1>{t('pages.wp_categories.title')}</h1>
            </div>
          </div>
        </section>

        <section id='second-screen' className='second-screen'>
          <div className='container-full'>
            {cardsData ? renderSubCategoryCards(cardsData, t('global.buttons.detail'), stateUrl, params.label) : null}
          </div>
        </section>
      </div>
    )
  }
}

WholesaleSubCategory.defaultProps = {
  lang: 'uk',
  url: '/wholesales-catalog/',
  region: 'ukraine'
}

WholesaleSubCategory.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default WholesaleSubCategory
