import produce from 'immer'
import {
  SET_WORK_TYPES
} from './constants'

// Initial State
export const initialState = {
  workTypes: null
}

const workTypesReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SET_WORK_TYPES:
      draft.workTypes = action.workTypes
      break
    default:
      return state
  }
})

export default workTypesReducer
