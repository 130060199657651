import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

export default class PartnerSlider extends Component {
  constructor (props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      slides: props.data
    }
  }

  componentDidMount () {
    const { data } = this.props

    this.setState({
      slides: data
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        slides: this.props.data
      })
    }
  }

  next () {
    this.slider.slickNext()
  }

  previous () {
    this.slider.slickPrev()
  }

  renderPartners () {
    const { slides } = this.state
    const content = slides.map((item, i) => (
      <Link
        key={item.img + i} to={{ pathname: item.link }} rel='noopener noreferrer' className='single-partner'
        target='_blank'
      >
        <img className='img' src={item.img} alt={`Partners logo${item.name}`} />
        <h5>{item.name}</h5>
      </Link>
    ))
    return content
  }

  render () {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 569,
          settings: {
            slidesToShow: 1
          }
        }
      ]
      // autoplay: true,
    }

    return (
      <div className='partners'>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.renderPartners()}
        </Slider>

        <div className='slider-navigation'>
          <button className='slick-prev' onClick={this.previous}>
            Previous
          </button>
          <button className='slick-next' onClick={this.next}>
            Next
          </button>
        </div>
      </div>
    )
  }
}

PartnerSlider.propTypes = {
  data: PropTypes.array
}
