import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { createStructuredSelector } from 'reselect'
import { selectCart } from '@containers/Cart/selectors'
import { refreshCart } from '@containers/Cart/actions'

import { connect } from 'react-redux'
import { TelegramClient } from 'messaging-api-telegram'
import SuccessSubmit from '@components/SuccessSubmit'
import telegram from '@global/telegram'

import '@scss/cart/index.scss'

const renderCarItem = (data) => {
  const content = data.map((item, i) =>
    <div className='orderCartItem' key={`cartProductItem${i}`} product={item}>
      <div className='left'>
        <div className='cartItemImage'>
          <img src={item.img} alt='Car product image' />
        </div>
        <div className='productInfo'>
          <Link to={`/catalog/products/${item.label}`}>
            <p className='p-bold'>{item.title}</p>
            <p>{item.count} шт</p>
          </Link>
        </div>
      </div>

      <div className='totalCost'>
        <span>{item.price * item.count} грн </span>
      </div>
    </div>
  )

  return content
}

const Ordering = (props) => {
  const { cart, dispatch } = props

  // console.log('Cart', cart)
  const [pay, setPay] = useState({
    check: 'nal',
    name: 'Наличными, при получении заказа'
  })
  const [delivery, setDelivery] = useState({
    check: 'samo',
    name: 'Самовывоз по адресу: г. Харьков, ул. Конева, 4'
  })
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')
  const [address, setAddress] = useState('')
  const [validPhone, setValidPhone] = useState(true)
  const [validName, setValidName] = useState(true)
  const [validMail, setValidMail] = useState(true)

  const [submitStatus, setSubmitStatus] = useState(false)

  const client = new TelegramClient({
    accessToken: process.env.REACT_APP_TELEGRAM_BOT
  })

  const payChange = (value, check) => {
    setPay({
      check: check,
      name: value
    })
  }
  const deliveryChange = (value, check) => {
    setDelivery({
      check: check,
      name: value
    })
  }

  const nameChange = (e) => {
    setName(e.target.value)
    setValidName(true)
  }

  const phoneChange = (e) => {
    setPhone(e.target.value)
    setValidPhone(true)
  }

  const mailChange = (e) => {
    setMail(e.target.value)
    setValidMail(true)
  }

  const messageChange = (e) => {
    setMessage(e.target.value)
  }

  const addressChange = (e) => {
    setAddress(e.target.value)
  }

  const validateField = (fieldName, value) => {
    // const {name, phone, email} = this.state
    let valid
    switch (fieldName) {
      case 'name':
        valid = value.length >= 1
        setValidName(valid)
        return valid
      case 'mail':
        valid = !!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        setValidMail(valid)
        return valid
      case 'phone':
        // valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        valid = true
        setValidPhone(valid)
        return valid
      default:
        return null
    }
  }

  const thanksModal = () => {
    const content = submitStatus
      ? <SuccessSubmit setStatus={(status) => setSubmitStatus(status)} status={submitStatus}>
        <h2>заявка <span>отправлена</span></h2>
        <p>Наш менеджер свяжется с вами в течении 2 дней</p>
        </SuccessSubmit>
      : null
    return content
  }

  const refresh = () => {
    setName('')
    setMessage('')
    setMail('')
    setPhone('')
    setAddress('')

    dispatch(refreshCart())
  }

  const submit = (e) => {
    e.preventDefault()

    // alert(`Form data: ${data}`)
    // console.log('Form data: ', data)

    validateField('name', name)
    validateField('mail', mail)
    validateField('phone', phone)

    const ids = telegram()

    if (validateField('name', name) && validateField('mail', mail) && validateField('phone', phone)) {
      send()
      ids.map((item) =>
        client.sendMessage(item,
          '<b>ОФОРМЛЕНИЕ ЗАКАЗА</b>\n\n' +
              `<b>Фамилия и имя: </b> ${name} \n` +
              `<b>Email: </b> <a href="mailto:${mail}">${mail}</a> \n` +
              `<b>Контактный номер: </b> <a href="tel:${phone}">${phone}</a> \n` +
              `<b>Сопроводительное письмо: </b> ${message} \n` +
              '\n\n<b>Cпособ оплаты:</b>\n' +
              `${pay.name} \n` +
              '\n\n<b>Cпособ доставки:</b>\n' +
              `${delivery.name} \n` +
              `${address}`,
          {
            disableWebPagePreview: true,
            disableNotification: true,
            parseMode: 'HTML'
          })
      )
      setSubmitStatus(true)
      refresh()
    }
  }

  async function send () {
    const products = cart.products.map((item) => item.id)
    const url = `${process.env.REACT_APP_API_KEY}shop/order`
    const data = {
      payment_type: pay.name,
      delivery_type: delivery.name,
      name: name,
      phone: phone,
      email: mail,
      order_type: 1,
      products: products,
      cost: cart.total,
      text: message
    }

    // console.log('Orde', data)

    try {
      const response = await fetch(url, {
        method: 'POST', // или 'PUT'
        body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const json = await response.json()
      // console.log('Успех:', JSON.stringify(json))
    } catch (error) {
      console.error('Ошибка:', error)
    }
  }
  return (
    <div className='page-content inner-pages ordering-page'>
      {thanksModal()}
      <section className='container'>
        <h1>ОФОРМЛЕНИЕ ЗАКАЗА</h1>
        <hr />
        <div className='left'>
          <div className='for-form'>
            <form onSubmit={(e) => e.preventDefault()}>
              <h3>ОПЛАТА И ДОСТАВКА </h3>

              <p className='p-bold'>Cпособ оплаты:</p>

              <label className={pay.check === 'nal' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='nal'
                  value='Наличными, при получении заказа'
                  checked={pay.check === 'nal'}
                  onChange={() => payChange('Наличными, при получении заказа', 'nal')}
                />
                Наличными, при получении заказа
              </label>
              <label className={pay.check === 'nal-new-post' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='nal-new-post'
                  value='Наложенный платёж'
                  checked={pay.check === 'nal-new-post'}
                  onChange={() => payChange('Наложенный платёж', 'nal-new-post')}
                />
                Наложенный платёж “Новая Почта”
              </label>
              <label className={pay.check === 'online' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='online'
                  value='Безналичный расчет'
                  checked={pay.check === 'online'}
                  onChange={() => payChange('Безналичный расчет', 'online')}
                />
                Безналичный расчет
              </label>
              <label className={pay.check === 'card' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='card'
                  value='Оплата банковской картой'
                  checked={pay.check === 'card'}
                  onChange={() => payChange('Оплата банковской картой', 'card')}
                />
                Оплата банковской картой
              </label>

              <p className='p-bold'>Cпособ доставки:</p>

              <label className={delivery.check === 'samo' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='samo'
                  value='Самовывоз по адресу: г. Харьков, ул. Конева, 4”'
                  checked={delivery.check === 'samo'}
                  onChange={() => deliveryChange('Самовывоз по адресу: г. Харьков, ул. Конева, 4”', 'samo')}
                />
                Самовывоз по адресу: г. Харьков, ул. Конева, 4
              </label>
              <label className={delivery.check === 'new-post' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='new-post'
                  value='Доставка в пункт выдачи “Новой Почты”'
                  checked={delivery.check === 'new-post'}
                  onChange={() => deliveryChange('Доставка в пункт выдачи “Новой Почты”', 'new-post')}
                />
                Доставка в пункт выдачи “Новой Почты”
              </label>
              <label className={delivery.check === 'adr' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='adr'
                  value='Адресная доставка курьером “Новой Почты”'
                  checked={delivery.check === 'adr'}
                  onChange={() => deliveryChange('Адресная доставка курьером “Новой Почты”', 'adr')}
                />
                Адресная доставка курьером “Новой Почты”
              </label>
              <label className={delivery.check === 'justin' ? 'active radioRow' : 'radioRow'}>
                <input
                  type='radio'
                  name='adr'
                  value='Justin'
                  checked={delivery.check === 'justin'}
                  onChange={() => deliveryChange('Justin', 'justin')}
                />
                Justin
              </label>
              {delivery.check === 'adr'
                ? <div>
                  <label>
                    Адрес доставки
                  </label>
                  <input
                    value={address} onChange={addressChange} type='text'
                    placeholder='Адрес доставки'
                  />
                  </div> : null}

              <h3 className='contact-info'>КОНТАКТНАЯ ИНФОРМАЦИЯ</h3>

              <label>
                Фамилия и имя
              </label>
              <input
                className={validName ? null : 'not-valid'} value={name} onChange={nameChange} type='text'
                placeholder='Иванов Иван'
              />
              <label>
                Контактный номер телефона
              </label>
              <input
                type='phone' className={validPhone ? null : 'not-valid'} value={phone} onChange={phoneChange}
                placeholder='+38 ХХХ - ХХХ - ХХ - ХХ'
              />

              <label>
                Email для связи
              </label>
              <input
                className={validMail ? null : 'not-valid'} type='email' value={mail} onChange={mailChange}
                placeholder='ivan.ivanov@mail.com'
              />

              <label>
                Сопроводительное письмо (необязательно)
              </label>
              <textarea value={message} onChange={messageChange} placeholder='Ваш текст' />
            </form>
          </div>
        </div>
        <div className='right'>
          <div className='orderCart'>

            <h3>КОРЗИНА</h3>
            <hr />
            <div className='cartBody'>
              {cart.products.length > 0 ? renderCarItem(cart.products) : null}
            </div>

            <div className='cartTotal'>
              <span>Сумма к опалате:</span>
              <span className='totalCost'>{cart.total} грн</span>
            </div>
            {cart.products.length > 0
              ? <button className='button' onClick={submit}>ПОДТВЕРДИТЬ ЗАКАЗ</button>
              : <Link to='/catalog' className='button'>выбор товаров</Link>}
          </div>
        </div>
      </section>
    </div>
  )
}

Ordering.propTypes = {
  dispatch: PropTypes.func,
  cart: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  cart: selectCart()
})

export default connect(mapStateToProps, null)(Ordering)
