import {
  SET_WORK_TYPES,
  GET_WORK_TYPES
} from './constants'

/**
 * Set WorkTypes
 *
 * @param workTypes array
 */

export function setWorkTypes (workTypes) {
  return {
    type: SET_WORK_TYPES,
    workTypes
  }
}

/**
 * Get WorkTypes
 *
 * @param workTypes array
 */

export function getWorkTypes () {
  //console.log('Saga')
  return {
    type: GET_WORK_TYPES
  }
}
