import produce from 'immer'
import {
  ADD_TO_CART,
  REFRESH_CART,
  CHANGE_CART
} from './constants'

// Initial State
export const initialState = {
  products: [
    // {
    //   id: 'f5cca6b0-c4b6-11e8-80c3-107b449167d0',
    //   label: '00000003566',
    //   title: 'Замок LBR 92-16/35-01 RIKO (14)---новый облегченный',
    //   price: 235,
    //   img: 'http://localhost/media/product-card_pTpoaRf.png',
    //   count: 1,
    //   desc: undefined
    // }
  ],
  total: 0
}

const cartReducer = (state = initialState, action) => produce(state, (draft) => {
  let temp; let cost = 0; let indexOfProduct

  switch (action.type) {
    case ADD_TO_CART:
      temp = {
        id: action.product.id,
        label: action.product.label,
        title: action.product.title,
        price: action.product.price,
        img: action.product.gallery[0].img,
        desc: action.description,
        count: 1
      }
      //console.log('Add', temp)
      indexOfProduct = draft.products.findIndex((item) => item.label === temp.label)
      //console.log('indexOfProduct', indexOfProduct)

      if (indexOfProduct !== -1) {
        temp = draft.products[indexOfProduct]
        temp.count += 1
        draft.products[indexOfProduct] = temp
      } else {
        draft.products.unshift(temp)
      }
      draft.products.forEach((item) => {
        cost += item.price * item.count
      })
      draft.total = cost
      break

    case CHANGE_CART:
      temp = {
        id: action.product.id,
        label: action.product.label,
        title: action.product.title,
        price: action.product.price,
        img: action.product.img,
        desc: action.desc,
        count: action.count
      }
      //console.log('Plus', temp)
      indexOfProduct = draft.products.findIndex((item) => item.label === temp.label)
     // console.log('indexOfProduct', indexOfProduct)
      if (action.count > 0) {
        if (indexOfProduct !== -1) {
          draft.products[indexOfProduct] = temp
        } else {
          draft.products.unshift(temp)
        }
      } else {
        draft.products.splice(indexOfProduct, 1)
      }
      draft.products.forEach((item) => {
        cost += item.price * item.count
      })
      draft.total = cost
      break

    case REFRESH_CART:
      draft.products = []
      draft.total = 0
      break
    default:
      return state
  }
})

export default cartReducer
