import {
  SET_CATEGORIES,
  SET_BRAND,
  SET_MATERIALS,
  SET_PRICE,
  REMOVE_BRAND,
  REMOVE_CATEGORIES,
  REMOVE_MATERIALS,
  REMOVE_PRICE,
  GET_CATEGORIES_LIST,
  SET_CATEGORIES_LIST, SET_COLOR, REMOVE_COLOR, CLEAR_SELECTOR
} from './constants'

/**
 * Set Categories
 *
 * @param categories string
 */

export const setCategories = (categories) => ({ type: SET_CATEGORIES, categories })
/**
 * Remove Categories
 *
 * @param categories string
 */

export const removeCategories = (categories) => ({ type: REMOVE_CATEGORIES, categories })
/**
 * Set Brands
 *
 * @param brands string
 */

export const setBrands = (brands) => ({ type: SET_BRAND, brands })
/**
 * Remove Brands
 *
 * @param brands string
 */
export const removeBrands = (brands) => ({ type: REMOVE_BRAND, brands })
/**
 * Set Materials
 *
 * @param materials string
 */
export const setMaterials = (materials) => ({ type: SET_MATERIALS, materials })
/**
 * Remove Materials
 *
 * @param materials string
 */

export const removeMaterials = (materials) => ({ type: REMOVE_MATERIALS, materials })
/**
 * Set Price
 *
 * @param price object
 */
export const setPrice = (price) => ({ type: SET_PRICE, price })
/**
 * Get Categories list
 *
 */

export const getCategoriesList = () => ({ type: GET_CATEGORIES_LIST })
/**
 * Set Categories list
 *
 * @param price object
 */
export const setCategoriesList = (list) => ({ type: SET_CATEGORIES_LIST, list })
/**
 * Set Colors
 *
 * @param Colors string
 */

export const setColors = (colors) => ({ type: SET_COLOR, colors })
/**
 * Remove Colors
 *
 * @param Colors string
 */

export const removeColors = (colors) => ({ type: REMOVE_COLOR, colors })

export const clearSelector = (selector) => ({ type: CLEAR_SELECTOR, selector })