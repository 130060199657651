import React, { Component } from 'react'

export default class ThirdStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit (event) {
    alert(`Сочинение отправлено: ${this.state.value}`)
    event.preventDefault()
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Фамилия
        </label>
        <input type='text' placeholder='Фамилия' />

        <label>
          Имя и отчество
        </label>
        <input placeholder='Имя и отчество' />

        <label>
          Email
        </label>
        <input placeholder='Email' />

        <label>
          Мобильный телефон
        </label>
        <input placeholder='Мобильный телефон' />

        <label>
          Дата рождения
        </label>
        <input placeholder='Дата рождения' />

        <input className='button' type='submit' value='Отправить' onClick={() => this.props.send()} />
      </form>
    )
  }
}
