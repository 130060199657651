import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { setLanguage, useTranslation } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import PartnerFrom from '@containers/FormPartner/index'
import PartnerSlider from '@components/PartnerSlider/index'
import Seo from '@components/Seo'

import { showLoader, hideLoader } from '@global/globalFunctions'

import NotFound from '@pages/404/index'

import '@scss/inner-pages/index.scss'
import '@scss/partners/index.scss'

const Partners = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [partnersPage, setPartnersPage] = useState()

  const [loading, setLoading] = useState(true)
  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}partners?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setPartnersPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }
  // console.log('partnersPage', partnersPage)

  if (loading) {
    return null
  } else if (!partnersPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages partners-page'>
        <Seo seo={{ title: partnersPage.seo_title, canonical: partnersPage.seo_canonical, description: partnersPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: partnersPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: partnersPage.first_text }} />
          </div>
          {/* <div className='container'> */}
          {/*  <div className='column-1-4' /> */}
          {/*  <div className='column-3-4 partners-place'> */}
          {/*    <PartnerSlider data={partnersPage.partners} /> */}
          {/*  </div> */}
          {/* </div> */}
          <div className='container-full'>
            <img className='img-full' src={partnersPage.file} alt='Partner page' />
          </div>
          <div className='container for-form'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h3>{t('pages.partners.formTitle')}</h3>
              <p>{t('pages.partners.formText')}</p>
              <PartnerFrom region={region} />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

Partners.defaultProps = {
  lang: 'ru',
  url: '/company/partners',
  region: 'ukraine'
}

Partners.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Partners)
