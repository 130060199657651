import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { createStructuredSelector } from 'reselect'
import { selectWorkTypes } from '@containers/Header/selectors'
import { connect } from 'react-redux'

import { useTranslation } from 'react-multi-lang'

import { renderCards, setCurrentLang } from '@global/globalFunctions'
import { selectLang, selectRegion } from '@global/selectors'

const FourthScreen = (props) => {
  const { workTypes, lang, region } = props
  const [stateUrl, setStateUrl] = useState('')

  const t = useTranslation()

  useEffect(() => {
    setCurrentLang(region, lang, setStateUrl)
  }, [lang, region])

  return (
    <section id='fourth-screen' className='fourth-screen'>
      <div className='container-full'>
        {workTypes && (
          region === 'ukraine'
            ? renderCards(workTypes, t('global.buttons.detail'), stateUrl, 'company/our-work/')
            : renderCards(workTypes.slice(0, 3), t('global.buttons.detail'), stateUrl, 'wholesales-catalog/')
        )}
      </div>
    </section>
  )
}

FourthScreen.propTypes = {
  lang: PropTypes.string,
  region: PropTypes.string,
  workTypes: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
  workTypes: selectWorkTypes(),
  lang: selectLang(),
  region: selectRegion()
})

export default connect(mapStateToProps, null)(FourthScreen)
