import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { setLang, setRegion, setUrl } from '@global/actions'

import { useParams } from 'react-router-dom'
import { renderPosts, textContent, showLoader, hideLoader, setCurrentLang } from '@global/globalFunctions'
import Seo from '@components/Seo'

import NotFound from '@pages/404/index'

import '@scss/blog/index.scss'

const BlogPost = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [blogPostPage, setBlogPostPage] = useState()
  const [posts, setPosts] = useState()
  const [stateUrl, setStateUrl] = useState()

  const [loading, setLoading] = useState(true)

  const params = useParams()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}posts?lang=${lang}&label=${params.label}`)
    res.json()
      .then((res) => setBlogPostPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }
  async function fetchPosts () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}posts?lang=${lang}`)
    res.json()
      .then((res) => setPosts(res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()
    fetchPosts()
    dispatch(setUrl(`${url}/${params.label}`))
    dispatch(setLang(lang))
    dispatch(setRegion(region))
    setCurrentLang(region, lang, setStateUrl)
  }, [lang, region, params.label])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }
  // console.log('partnersPage', partnersPage)

  if (loading) {
    return null
  } else if (!blogPostPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages blog-page post-page'>
        <Seo seo={{ title: blogPostPage.seo_title, canonical: blogPostPage.seo_canonical, description: blogPostPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='container-title'>
              <h1>{blogPostPage.title}</h1>
            </div>
            <div className='column-1-4' />
          </div>

          {blogPostPage.content ? textContent(blogPostPage.content) : null}

          <div className='container posts'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h2>Полезные материалы:</h2>
              {posts ? renderPosts(posts, stateUrl) : null}
            </div>
          </div>
        </section>
      </div>
    )
  }
}

BlogPost.defaultProps = {
  lang: 'ru',
  url: '/blog/post',
  region: 'ukraine'
}

BlogPost.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(BlogPost)
