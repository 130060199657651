import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { setLang, setRegion, setUrl } from '@global/actions'
import { hideLoader, showLoader } from '@global/globalFunctions'
import { setLanguage, useTranslation } from 'react-multi-lang'

import WSColorPicker from '@containers/ColorPicker/WS_colorpicker'
import NotFound from '@pages/404'

import '@scss/WholesalesProductCard/index.scss'

const WholesaleProductPage = (props) => {
  const { lang, region, url, dispatch } = props
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(null)

  const [hasError, setErrors] = useState(false)

  const params = useParams()
  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/catalog_products/?lang=${lang}&region=${region}&label=${params.product}`)
    res.json()
      .then((res) => {
        setProduct(res[0])
        // console.log('API', res[0])
        setCurrentImage(res[0].photos[0].photo)
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err))
  }

  // console.log(params)
  useEffect(() => {
    dispatch(setUrl(`/wholesales-catalog/${params.category}/${params.label}/${params.product}`))
  }, [])

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!product && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='wholesale-product-page'>
        <div className='image-container'>
          <div className='image'>
            <img src={currentImage} />
          </div>
          <div className='image'>
            <img src={product.picture} />
          </div>
        </div>
        <div className='content-container'>
          <h1 className='page-tittle'>{product.title}</h1>

          <div className='color-picker-wrapper'>
            <h4 className='second-tittle'>{t('pages.catalog.color')}</h4>
            <div className='color-picker-container'>
              <WSColorPicker setImage={setCurrentImage} data={product.photos} />
            </div>
          </div>

          <div className='properties-wrapper'>
            <h4 className='second-tittle'>{t('pages.catalog.properties')}:</h4>
            <div className='properties-container'>
              <div dangerouslySetInnerHTML={{ __html: product.table }} />
            </div>
          </div>

          <div className='description-wrapper'>
            <h4 className='second-tittle'>{t('pages.catalog.description')}</h4>
            <div className='properties-container' dangerouslySetInnerHTML={{ __html: product.description }} />
          </div>
        </div>
      </div>
    )
  }
}

WholesaleProductPage.defaultProps = {
  lang: 'ua',
  url: '/wholesales-catalog/category/label/product',
  region: 'ukraine'
}

WholesaleProductPage.propTypes = {
  dispatch: PropTypes.func,
  url: PropTypes.string,
  lang: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(WholesaleProductPage)
