import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { setLang, setUrl } from '@global/actions'

import { useParams, useLocation } from 'react-router-dom'
import { renderProducts, showLoader, hideLoader } from '@global/globalFunctions'
import { addToCart } from '@containers/Cart/actions'

import NotFound from '@pages/404/index'

import '@scss/shop/index.scss'


const gallery = (data) => {
  const content = data.map((item, i) => (
    <img src={item.img} key={`Gallery${i}`} alt={`Gallery${i}`} />
  ))

  return content
}

const renderProperties = (data) => {
  const content = data.map((item, i) => (
    <div key={`properties${i}`} className='row'>
      <p className='name'>{item.title}</p>
      <p className='desc'>{item.value}</p>
    </div>
  ))

  return content
}

const renderReviews = (data) => {
  const content = data.map((item, i) => (
    <div key={`reviews${i}`} className='single-review'>
      <h4>
        пользователь
      </h4>
      <div className='stars'>
        <ul>
          <li />
          <li />
          <li />
          <li />
          <li />
        </ul>

        <p>24 мая 2020г.</p>
      </div>
      <p>
        {item.text}
      </p>
    </div>
  ))

  return content
}

const Product = (props) => {
  const { dispatch, lang, url } = props
  const [hasError, setErrors] = useState(false)
  const [productPage, setProductPage] = useState()
  const [products, setProducts] = useState()

  const params = useParams()
  const location = useLocation()

  const [loading, setLoading] = useState(true)

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/products?lang=${lang}&label=${params.id}`)
    res.json()
      .then((res) => setProductPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  // console.log(productPage)

  async function fetchProducts () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/products?lang=${lang}&category=${productPage.category.label}`)
    res.json()
      .then((res) => setProducts(res))
      .then((res) => console.log('Category products', res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()
    dispatch(setUrl(`${url}${params.id}`))
    dispatch(setLang(lang))
  }, [location, lang])

  useEffect(() => {
    if (productPage) {
      fetchProducts()
    }
  }, [productPage])

  const onAdd = () => {
    dispatch(addToCart(productPage))
  }

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!productPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages product-page'>
        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='left'>
              {gallery(productPage.gallery)}
            </div>
            <div className='right'>
              <div className='title'>
                <h2>{productPage.title}</h2>
                <span className='cost'>{productPage.price} <span>грн</span></span>
              </div>

              <div className='rating'>
                <div className='stars'>
                  <p>Рейтинг товара:</p>
                  <ul>
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                  </ul>

                  <div className='help-info' title='Рейтинг товара формируется исходя из отзывов наших клиентов' />
                </div>
                <div className='info'>
                  <p className='technical'>
                    * Оптовые цены уточняйте у менеджера
                  </p>
                </div>
              </div>

              <hr />

              <div className='filters'>
                {/* <h4> */}
                {/*  ЦВЕТ ИЗДЕЛИЯ: */}
                {/* </h4> */}

                {/* <div className='colors'> */}
                {/*  <div className='form-group'> */}
                {/*    <input type='radio' id='white' /> */}
                {/*    <label htmlFor='white'>White</label> */}
                {/*  </div> */}
                {/*  <div className='form-group'> */}
                {/*    <input type='radio' id='black' /> */}
                {/*    <label htmlFor='black'>Black</label> */}
                {/*  </div> */}
                {/*  <div className='form-group'> */}
                {/*    <input type='radio' id='red' /> */}
                {/*    <label htmlFor='red'>Red</label> */}
                {/*  </div> */}
                {/* </div> */}

                <div className='buttons'>
                  <button onClick={onAdd} className='button'>
                    КУПИТЬ
                  </button>
                </div>

                <div className='characteristics'>
                  <h4>ХАРАКТЕРИСТИКИ</h4>

                  {productPage.properties ? renderProperties(productPage.properties) : null}
                </div>

                <div className='characteristics'>
                  <h4>ОПИСАНИЕ</h4>

                  <div dangerouslySetInnerHTML={{ __html: productPage.description }} />
                </div>

                <div className='characteristics delivery'>
                  <h4>УСЛОВИЯ ДОСТАВКИ И ОПЛАТЫ</h4>

                  <div className='row'>
                    <p className='name'>Способы доставки:</p>
                    <p className='desc'>
                      <b>Нова пошта</b> <br />
                      <b>Justin</b> Бесплатно при условии:
                      Заказ на сумму свыше 1 000 грн. доставляется бесплатно.
                    </p>
                  </div>

                  <div className='row'>
                    <p className='name'>Способы оплаты: </p>
                    <p className='desc'>
                      <b>Наложенный платеж "Нова Почта"</b> <br />
                      <b>Безналичный расчет</b> <br />
                      <b>Наличными</b><br />
                      <b>Оплата картой Visa, Mastercard - LiqPay</b><br />
                      <b>Наложенный платеж "Justin"</b><br />
                      <b>Наличными</b>
                    </p>
                  </div>

                  <div className='row'>
                    <p className='name'>Гарантия:</p>
                    <p className='desc'>
                      <b>Гарантия 12 месяцев.</b>
                    </p>
                  </div>

                  <div className='row'>
                    <p className='name'>Регионы доставки:</p>
                    <p className='desc'>
                      <b>Украина, все регионы</b>
                    </p>
                  </div>
                </div>

                <div className='reviews'>
                  <div className='title'>
                    <h4>ОТЗЫВЫ ({productPage.reviews.length})</h4>

                    {productPage.reviews.length > 0
                      ? <div className='stars'>
                        <p>Рейтинг товара:</p>
                        <ul>
                          <li />
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                        <div className='help-info' />
                        </div>
                      : <div className='stars'>
                        <div className='help-info' />
                        </div>}

                  </div>

                  {productPage.reviews.length > 0 ? renderReviews(productPage.reviews) : null}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id='second-screen' className='second-screen'>
          <div className='container'>
            <h2>АНАЛОГИЧНЫЕ ТОВАРЫ</h2>
            <hr />

            <div className='products'>
              {products ? renderProducts(products) : null}
            </div>
          </div>
        </section>

      </div>
    )
  }
}

Product.defaultProps = {
  lang: 'ru',
  url: '/catalog/products/'
}

Product.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string
}

export default connect(null, null)(Product)
