/**
 * WorkTypes selectors
 */

import { createSelector } from 'reselect'
import { initialState } from './reducer'

const cartState = (state) => state.shop.cart || initialState

const selectCart = () => createSelector(cartState,
  (pState) => pState)

export {
  selectCart
}
