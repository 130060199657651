import React, { Component } from 'react'

export default class FirstStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit (event) {
    alert(`Сочинение отправлено: ${this.state.value}`)
    event.preventDefault()
  }

  render () {
    return (
        <form onSubmit={this.handleSubmit}>
            <label>
                ИНН
            </label>

            <input  type='text' placeholder='________' />
            <label>
                ОГРН
            </label>

            <input  placeholder='ОГРН организации' />

            <label>
                КПП
            </label>

            <input  placeholder='КПП организации' />
            <label>
                Название организации
            </label>

            <input  placeholder='Название организации' />

            <label>
                Фамилия руководителя
            </label>

            <input  placeholder='Фамилия руководителя' />

            <label>
                Имя и отчество руководителя
            </label>

            <input  placeholder='Имя и отчество руководителя' />

            <label>
                Должность руководителя
            </label>

            <input  placeholder=' Должность руководителя' />

            <label>
                Email
            </label>

            <input  placeholder='Email' />

            <label>
                Городской телефон
            </label>

            <input  placeholder='Городской телефон' />
            <input className='button' type='submit' value='Отправить' onClick={() => this.props.send() } />
        </form>
    )
  }
}
