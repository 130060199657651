import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { Link } from 'react-router-dom'

import { showLoader, hideLoader } from '@global/globalFunctions'
import Seo from '@components/Seo'

import NotFound from '@pages/404/index'

import '@scss/inner-pages/index.scss'

import Certificate from '@img/certeficat.png'

function renderTypes (data) {
  const content = data.map((item, i) => (
    <Link key={`types${i}`} to={{ pathname: item.link }} target='_blank' className='single-type'>
      <div className='number'>
        0
        {i + 1}
      </div>
      <div className='content'>
        <h4>{item.title}</h4>
        <p>{item.content}</p>
      </div>
    </Link>
  ))

  return content
}

const SalesPolicy = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [salesPage, setSalesPage] = useState()

  const [loading, setLoading] = useState(true)

  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}sales_policy?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setSalesPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region])

  // console.log('salesPage', salesPage)

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!salesPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages sales-page'>
        <Seo seo={{ title: salesPage.seo_title, canonical: salesPage.seo_canonical, description: salesPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: salesPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: salesPage.first_text }} />
          </div>
          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h2>{t('pages.policy.workWith')}</h2>
              {renderTypes(salesPage.sales_partners)}
            </div>
          </div>
          <div className='container-full'>
            <img className='img-full' src={salesPage.file} alt='Sales page' />
          </div>
          <div className='container'>
            <div className='column-1-4'>
              <p className='quote'>{t('pages.policy.quote')}</p>
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: salesPage.content }} />
          </div>
          <div className='container'>
            <div className='column-1-4' />

            <div className='column-3-4 block-certificate'>
              <div className='content' dangerouslySetInnerHTML={{ __html: salesPage.guarantee_text }} />
              <div className='img-certificate'>
                <img src={Certificate} alt='Certificate' />
                <h5>{salesPage.certificate_title}</h5>
                <p>{salesPage.certificate_text}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

SalesPolicy.defaultProps = {
  lang: 'ru',
  url: '/company/sales-policy',
  region: 'ukraine'
}

SalesPolicy.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(SalesPolicy)
