import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function renderSocials (data, footer) {
  const string = footer ? '-dark' : ''
  const content = data.map((item, i) => (
    <li key={`icon-${i}`}>
      <Link to={{ pathname: item.link }} target='_blank'><i className={`icon-${item.label}${string}`} /></Link>
    </li>
  ))
  return content
}

export default function Socials (props) {
  const { footer } = props
  const [hasError, setErrors] = useState(false)
  const [socials, setSocials] = useState(false)
  const [stateFooter, setStateFooter] = useState(footer)

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}links?lang=ru`)
    res.json()
      .then((res) => setSocials(res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setStateFooter(footer)
  }, [footer])
  // console.log('Size', size);

  return (
    <ul className={stateFooter ? 'social-row' : 'socials'}>
      {socials ? renderSocials(socials, stateFooter) : null}
    </ul>
  )
}

Socials.propTypes = {
  footer: PropTypes.bool
}
