import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Socials from '@components/Socials'

import { useTranslation } from 'react-multi-lang'

import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectWorkTypes } from '@containers/Header/selectors'
import { getWorkTypes } from '@containers/Header/actions'

import Logo from '@img/logo-dark.svg'
import PropTypes from 'prop-types'
import { selectLang, selectUrl, selectRegion } from '@global/selectors'
import SelectRegionModal from "@components/SelectRegionModal/SelectRegionModal";

const renderWorkSite = (data, url) => {
  const content = data.map((item, i) =>
    <li key={`workCatalog${i}`}>
      <Link to={`${url}company/our-work/${item.label}`}>{item.title}</Link>
    </li>
  )
  return content
}

const Footer = (props) => {
  const { dispatch, workTypes, lang, region } = props

  const [stateUrl, setStateUrl] = useState('')
  const [contact, setContact] = useState({
    title: '',
    first_text: '',
    guarantee_text: ''
  })
  const [hasError, setErrors] = useState(false)
  const [loading, setLoading] = useState(true)

  const t = useTranslation()

  if (!workTypes) {
    // console.log('Saga')
    dispatch(getWorkTypes())
  }

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}contact?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setContact(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  // console.log('Content', contact)
  useEffect(() => {
    fetchData()

    if (region === 'ukraine') {
      switch (lang) {
        case 'ru':
          setStateUrl('/ru/')
          break
        case 'uk':
          setStateUrl('/')
          break
        case 'en':
          setStateUrl('/en/')
          break
        default:
          break
      }
    } else if (region === 'russia') {
      switch (lang) {
        case 'ru':
          setStateUrl('/russia/')
          break
        case 'uk':
          setStateUrl('/russia/ua/')
          break
        case 'en':
          setStateUrl('/russia/en/')
          break
        default:
          break
      }
    } else if (region === 'european') {
      switch (lang) {
        case 'ru':
          setStateUrl('/european/ru/')
          break
        case 'uk':
          setStateUrl('/european/ua/')
          break
        case 'en':
          setStateUrl('/european/')
          break
        default:
          break
      }
    }
  }, [lang, region])

  return (
    <footer>
      <div className='container'>
        <div className='column-1-4'>
          <Link to='/' className='footer-logo'>
            <img src={Logo} alt='Astext logo' />
          </Link>
          <div dangerouslySetInnerHTML={{ __html: contact.main_contact }} />

          <Socials footer />
        </div>

        <div className='column-3-4'>
          <ul>
            <li>
              <Link to={`${stateUrl}company`} className='title'>{t('global.menu.company')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}company`}>{t('global.menu.aboutCompany')}</Link>
            </li>
            {/* <li> */}
            {/*  <Link to='/'>Хронология успеха</Link> */}
            {/* </li> */}
            <li>
              <Link to={`${stateUrl}company/office`}>{t('global.menu.team')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}company/sales-policy`}>{t('global.menu.policy')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}company/vacancies`}>{t('global.menu.vacancies')}</Link>
            </li>
          </ul>

          <ul>
            <li>
              <Link to={`${stateUrl}company/our-work`} className='title'>{t('global.menu.types')}</Link>
            </li>
            {workTypes ? renderWorkSite(workTypes, stateUrl) : null}
          </ul>

          <ul>
            <li>
              <a className='title'>{t('global.menu.usefullInfo')}</a>
            </li>
            <li>
              <Link to={`${stateUrl}info/certificates`}>{t('global.menu.certificates')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}info/documents`}>{t('global.menu.documents')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}catalog`}>{t('global.menu.catalogP')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}info/faq`}>{t('global.menu.faq')}</Link>
            </li>
            <li>
              <Link to={`${stateUrl}blog`} className='title'>{t('global.menu.blog')}</Link>
            </li>
          </ul>

          {/* <ul> */}
          {/*  <li> */}
          {/*    <Link to='/' className='title'>Для бизнес-клиентов</Link> */}
          {/*  </li> */}
          {/*  <li> */}
          {/*    <Link to='/'>Личный кабинет</Link> */}
          {/*  </li> */}
          {/*  <li> */}
          {/*    <Link to='/'>Преимущества</Link> */}
          {/*  </li> */}
          {/*  <li> */}
          {/*    <Link to='/company/sales-policy'>Условия сотрудничества </Link> */}
          {/*  </li> */}
          {/*  <li> */}
          {/*    <Link to='/registration'>Стать бизнес клиентом</Link> */}
          {/*  </li> */}
          {/* </ul> */}
        </div>
      </div>
      <hr />

      <div className='container footer-bottom'>
        <div className='left'>
          <span>
            ©ASTEX
          </span>
          <span>
            <Link to='/'>{t('footer.policyS')}</Link>
          </span>
          <span>
            <Link to='/'>{t('footer.policy')}</Link>
          </span>
        </div>
        <div className='right'>
          <span>
            {t('footer.createBy')} <Link to={{ pathname: 'https://art-cube.com.ua' }} target='_blank' rel='noopener noreferrer'>Art Сube</Link>
          </span>
        </div>
      </div>
      <SelectRegionModal />
    </footer>
  )
}

Footer.propTypes = {
  dispatch: PropTypes.func,
  workTypes: PropTypes.any,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  workTypes: selectWorkTypes(),
  url: selectUrl(),
  lang: selectLang(),
  region: selectRegion()
})

export default connect(mapStateToProps, null)(Footer)
