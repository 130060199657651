import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectWorkTypes } from '@containers/Header/selectors'

import {renderCards, showLoader, hideLoader, setCurrentLang} from '@global/globalFunctions'

import Seo from '@components/Seo'
import NotFound from '@pages/404/index'

import '@scss/work/index.scss'
import {setLang, setRegion, setUrl} from '@global/actions'

const WorkTypes = (props) => {
  const { dispatch, workTypes, lang, url, region } = props
  const [hasError, setErrors] = useState(false)
  const [workTypePage, setWorkTypePage] = useState()
  const [stateUrl, setStateUrl] = useState('')
  // console.log(workTypePage)
  const [loading, setLoading] = useState(true)
  const t = useTranslation()
  console.log(stateUrl)
  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}work_type_page?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setWorkTypePage(res[0]))
      .then(() => setLoading(false))
      .catch(() => setErrors(true))
  }

  useEffect(() => {
    dispatch(setUrl(url))
  }, [])

  useEffect(() => {
    showLoader()
    fetchData()
    dispatch(setLang(lang))
    dispatch(setRegion(region))
    setLanguage(lang)
    setCurrentLang(region, lang, setStateUrl)

  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  // console.log('Work types', workTypes)
  if (loading) {
    return null
  } else if (!workTypePage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages work-types-page'>
        <Seo seo={{ title: workTypePage.seo_title, canonical: workTypePage.seo_canonical, description: workTypePage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: workTypePage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: workTypePage.first_text }} />
          </div>
        </section>

        <section id='second-screen' className='second-screen'>
          <div className='container-full'>
            {workTypes ? renderCards(workTypes, t('global.buttons.detail'), stateUrl, 'company/our-work/') : null}
          </div>
        </section>

        <section id='third-screen' className='third-screen'>
          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: workTypePage.second_text }} />
          </div>
        </section>
      </div>
    )
  }
}

WorkTypes.defaultProps = {
  lang: 'ru',
  url: '/company/our-work',
  region: 'ukraine'
}

WorkTypes.propTypes = {
  dispatch: PropTypes.func,
  workTypes: PropTypes.any,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  workTypes: selectWorkTypes()
})

export default connect(mapStateToProps, null)(WorkTypes)
