import React, { useState, useEffect } from 'react'
import MultilevelSidebar from 'react-multilevel-sidebar'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-multi-lang'

import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectLang, selectRegion, selectUrl } from '@global/selectors'
import { selectWorkTypes } from '@containers/Header/selectors'
import { getWorkTypes } from '@containers/Header/actions'
import { setCurrentLang } from '@global/globalFunctions'
import { LOCAL_USER_GEO } from '@global/constants'

import 'react-multilevel-sidebar/src/Sidebar.css'

const MobileMenu = (props) => {
  const { dispatch, url, lang, region } = props

  const [isOpen, setOpen] = useState(false)
  const [stateUrl, setStateUrl] = useState('')

  const t = useTranslation()

  useEffect(() => {
    dispatch(getWorkTypes())
    setCurrentLang(region, lang, setStateUrl)
  }, [lang])

  const handleSidebarToggle = (temp) => {
    setOpen(temp)
  }

  const handleClick = (itemOptions) => {
    if (itemOptions.country) {
      localStorage.setItem(LOCAL_USER_GEO, itemOptions.country)
    }
  }

  const regionSelector = () => {
    if (region === 'ukraine') {
      return ([
        {
          id: 30,
          name: 'RU',
          to: `/ru${url}`
        },
        {
          id: 31,
          name: 'UA',
          to: `${url}`
        },
        {
          id: 32,
          name: 'EN',
          to: '/en'
        }])
    }
    if (region === 'russia') {
      return [
        {
          id: 30,
          name: 'RU',
          to: `/russia${url}`
        },
        {
          id: 32,
          name: 'EN',
          to: '/russia/en'
        }]
    }
    if (region === 'european') {
      return ([
        {
          id: 32,
          name: 'EN',
          to: '/european'
        }])
    }

    return (
      [
        {
          id: 32,
          name: 'EN',
          to: '/european'
        }]
    )
  }

  const options = [
    {
      content: [{
        id: 1,
        name: t('global.menu.company'),
        children: [
          {
            content: [
              {
                id: 2,
                name: t('global.menu.aboutCompany'),
                to: `${stateUrl}company`
              }
            ]
          },
          {
            content: [
              {
                id: 3,
                name: t('global.menu.team'),
                to: `${stateUrl}company/office`
              }
            ]
          },
          {
            content: [
              {
                id: 4,
                name: t('global.menu.partners'),
                to: `${stateUrl}company/partners`
              }
            ]
          },
          {
            content: [
              {
                id: 5,
                name: t('global.menu.policy'),
                to: `${stateUrl}company/sales-policy`
              }
            ]
          },
          {
            content: [
              {
                id: 6,
                name: t('global.menu.vacancies'),
                to: `${stateUrl}company/vacancies`
              }
            ]
          }
        ]
      }],
      hideBorder: true
    },
    {
      content: [
        {
          id: 7,
          name: t('global.menu.usefullInfo'),
          children: [
            {
              content: [
                {
                  id: 8,
                  name: t('global.menu.certificates'),
                  to: `${stateUrl}info/certificates`
                }
              ]
            },
            {
              content: [
                {
                  id: 9,
                  name: t('global.menu.documents'),
                  to: `${stateUrl}info/documents`
                }
              ]
            },
            {
              content: [
                {
                  id: 10,
                  name: t('global.menu.faq'),
                  to: `${stateUrl}info/faq`
                }
              ]
            }
          ]
        }
      ],
      hideBorder: true
    },
    {
      content: [{ id: 11, name: t('global.menu.blog'), to: `${stateUrl}blog` }]
    },
    {
      hideBorder: true,
      content: [{ id: 12, name: t('global.menu.types'), to: `${stateUrl}company/our-work` }]
    },
    {
      content: [{ id: 16, name: t('global.menu.office'), to: `${stateUrl}contact/main-office` }]
    },
    {
      content: regionSelector()
    },
    {
      content: [
        { id: 33, name: t('global.menu.regions.ukraine'), to: '/', country: 'Ukraine' },
        // { id: 34, name: t('global.menu.regions.russia'), to: '/russia', country: 'Russia' },
        { id: 35, name: t('global.menu.regions.europe'), to: '/european', country: 'Europe' }
      ]
    }
  ]

  return (
    <div>
      <MultilevelSidebar
        open={isOpen}
        onToggle={handleSidebarToggle}
        options={options}
        onItemClick={handleClick}
      />
      {/* using in our button to open the sidebar */}
      <div className='mobile-menu' onClick={() => handleSidebarToggle(true)}>
        <i className='icon-menu' />
      </div>
    </div>
  )
}

MobileMenu.propTypes = {
  dispatch: PropTypes.func,
  url: PropTypes.string,
  lang: PropTypes.string,
  region: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  workTypes: selectWorkTypes(),
  url: selectUrl(),
  lang: selectLang(),
  region: selectRegion()
})

export default connect(mapStateToProps, null)(MobileMenu)
