import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '0',
    left: 'auto',
    right: '0',
    bottom: 'auto'
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)'
  }
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#astex')

export default function SuccessSubmit (props) {
  const { children, status, setStatus } = props
  const [modalIsOpen, setIsOpen] = React.useState(status)

  useEffect(() => {
    setIsOpen(status)
  }, [status])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setStatus(false)
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <div className='modal-content'>
        <div className='modal-close' onClick={closeModal}>+</div>
        <div className='modal-content-top'>
          {children}
        </div>
        <div className='modal-content-bottom'>
          <button onClick={closeModal}>закрыть</button>
        </div>
      </div>
    </Modal>
  )
}

SuccessSubmit.propTypes = {
  children: PropTypes.any,
  status: PropTypes.bool,
  setStatus: PropTypes.func
}
