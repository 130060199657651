import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { setLanguage, useTranslation } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { Link, useParams } from 'react-router-dom'

import ProductSlider from '@containers/ProductsSlider'

import { renderPosts, textContent, showLoader, hideLoader, setCurrentLang } from '@global/globalFunctions'

import NotFound from '@pages/404/index'

import '@scss/work/index.scss'
import Seo from '@components/Seo'
import {clearSelector, setCategories} from '@containers/Shop/Filters/actions'

const renderSubCategories = (data, btn, lang, dispatch, clear) => {
  let className = 'services-card'
  if (data.length === 2) {
    className = 'services-card two'
  } else if (data.length === 4) {
    className = 'services-card four'
  }
  const content = data.map((item, i) =>
    <div key={`subCategory${i}`} className={className} style={item.thumbnail ? { backgroundImage: `url(${item.thumbnail})` } : null}>
      <div className='card-head'>
        <h2>{item.title}</h2>
      </div>
      <Link
        onClick={() => clear()(item.label)}
        to={`${lang}catalog`}
        className='button'
      >
        {btn || 'ПОДРОБНЕЕ'}
      </Link>
    </div>
  )
  return content
}

const SingleWorkType = (props) => {
  const { dispatch, lang, url, region } = props
  const [hasError, setErrors] = useState(false)
  const [workPage, setWorkPage] = useState()
  const [posts, setPosts] = useState()
  const [stateUrl, setStateUrl] = useState()
  const [loading, setLoading] = useState(true)

  const params = useParams()
  const t = useTranslation()

  const handleClick = () => {
    dispatch(clearSelector('categories'))
    dispatch(clearSelector('materials'))
    dispatch(clearSelector('brands'))
    dispatch(clearSelector('colors'))
    return function setSelector (filter) {
      dispatch(setCategories(filter))
    }
  }

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}work_types?lang=${lang}&label=${params.label}&region=${region}`)
    res.json()
      .then((res) => setWorkPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  async function fetchPosts () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}posts?lang=${lang}`)
    res.json()
      .then((res) => setPosts(res))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchPosts()
    fetchData()
    setCurrentLang(region, lang, setStateUrl)
    dispatch(setUrl(`/company/our-work/${params.label}`))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [params, lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!workPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages single-work-page'>
        <Seo seo={{ title: workPage.seo_title, canonical: workPage.seo_canonical, description: workPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: workPage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: workPage.first_content }} />
          </div>

          <div className='container-full card-place'>
            {renderSubCategories(workPage.category, t('global.buttons.detail'), stateUrl, dispatch, handleClick)}
          </div>

          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: workPage.second_content }} />
          </div>
        </section>
        <ProductSlider lang={lang} />
        <section className='second-screen'>
          {workPage.content ? textContent(workPage.content) : null}
        </section>
        <section className='third-screen'>
          <div className='container posts'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <h2>{t('global.titles.usefullInfo')}</h2>
              {posts ? renderPosts(posts.slice(0, 3)) : null}
            </div>
          </div>
        </section>

      </div>
    )
  }
}

SingleWorkType.defaultProps = {
  lang: 'ru',
  url: '/',
  region: 'ukraine'
}

SingleWorkType.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(SingleWorkType)
