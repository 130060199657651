import React, { Component } from 'react'
import {Link} from "react-router-dom";

export default class FourthStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit (event) {
    alert(`Сочинение отправлено: ${this.state.value}`)
    event.preventDefault()
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Пароль
        </label>
        <input type='text' placeholder='Пароль' />

        <label>
          Повторите пароль
        </label>
        <input placeholder='Повторите пароль' />

          <Link className='button' type='submit' value='Отправить' to='/'>Отправить</Link>
      </form>
    )
  }
}
