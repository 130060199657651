import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import '@scss/inner-pages/index.scss'

export default function NotFound () {
  const [content, setContent] = useState({
    title: '404',
    content: 'Так уж получилось, что из множества страниц нашего сайта вы оказались как раз на той, которая уже не существует… '
  })

  return (
    <div className='page-content inner-pages not-found'>
      <section id='first-screen' className='first-screen'>
        <div className='container'>
          <h1>{content.title}</h1>
          <p>{content.content}</p>
          <Link to='/' className='button'>НА ГЛАВНУЮ</Link>
        </div>
      </section>
    </div>
  )
}
