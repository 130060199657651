import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectLang } from '@global/selectors'

import { Link } from 'react-router-dom'
import { addToCart } from '@containers/Cart/actions'

const ProductCard = (props) => {
  const { data, gallery, dispatch, lang } = props

  const [url, setStateUrl] = useState('/')

  useEffect(() => {
    switch (lang) {
      case 'ru':
        setStateUrl('/ru/')
        break
      case 'uk':
        setStateUrl('/')
        break
      case 'en':
        setStateUrl('/en/')
        break
      default:
        break
    }
  }, [lang])

  const onAdd = () => {
    // console.log(props)
    dispatch(addToCart(data))
  }

  return (
    <Link to={`${url}catalog/products/${data.label}`} className='product-card'>
      <div className='img-block' style={{ backgroundImage: `url(${gallery ? gallery.img : null})` }}>
        <div className='favorite'>
          <i className='icon-favorite' />
        </div>
      </div>

      <div className='info-block'>
        <p className='p-bold'>
          {data.title}
        </p>
        <div className='p-grey' dangerouslySetInnerHTML={{ __html: data.description }} />
        <p className='cost'>{data.price} грн</p>
      </div>
    </Link>
  )
}

ProductCard.propTypes = {
  data: PropTypes.object,
  gallery: PropTypes.object,
  dispatch: PropTypes.func,
  lang: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  lang: selectLang()
})

export default connect(mapStateToProps, null)(ProductCard)
