/**
 * WorkTypes selectors
 */

import { createSelector } from 'reselect'
import { initialState } from './reducer'

const workTypesState = (state) => state.site.workTypes || initialState

const selectWorkTypes = () => createSelector(workTypesState,
  (pState) => pState.workTypes)

export {
  selectWorkTypes
}
