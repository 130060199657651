import React from 'react'
// eslint-disable-next-line import/no-unresolved
import Router from './app/global/router'
import { Provider } from 'react-redux'
import { store } from './app/store'

const App = () => {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  )
}

export default App
