import {
  all, put, takeLatest
} from 'redux-saga/effects'
import axios from 'axios'

import { setCategoriesList } from './actions'
import { GET_CATEGORIES_LIST } from './constants'

export function * fetchCategoriesList () {
  try {
    const url = `${process.env.REACT_APP_API_KEY}shop/categories?lang=ru`
    const categories = yield axios
      .get(url)
      .then((resp) => resp.data)
      .catch((error) => {
        const { data } = error.response
        throw new Error(data.message)
      })

    if (categories) {
      yield put(setCategoriesList(categories))
      // console.log(categories)
    }
  } catch (err) {
    console.log('workTypes catch error', err)
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function * createPublicUserSaga () {
  yield all([
    takeLatest(GET_CATEGORIES_LIST, fetchCategoriesList)
  ])
}
