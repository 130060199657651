import React, { useEffect, useState } from 'react'

import PageTittle from '@components/Page-tittle/PageTittle'
// import Breadcrumbs from "@components/Breadcrumbs";
import WholesaleProductCard from '@components/WholesaleProductCard/WholesaleProductCard'

import '@scss/WholesaleCatalog/index.scss'
import { setLang, setRegion, setUrl } from '@global/actions'
import { hideLoader, showLoader } from '@global/globalFunctions'
import { setLanguage, useTranslation } from 'react-multi-lang'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NotFound from '@pages/404'
import { useParams } from 'react-router-dom'

const WholesaleCatalog = (props) => {
  const { lang, region, url, dispatch } = props

  const [catalog, setCatalog] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasError, setErrors] = useState(false)

  const params = useParams()
  const t = useTranslation()

  const [next, setNext] = useState(0)
  const postsPerPage = 9

  const handleShowMorePosts = () => {
    const currentPosition = next + postsPerPage
    setNext(currentPosition)
    fetchMoreData(currentPosition)
  }

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/catalog_products/?lang=${lang}&region=${region}&category=${params.label}&start=${next}&amount=${postsPerPage}`)
      const data = await res.json()
      setCatalog(data.results)
      setLoading(false)
    } catch (e) {
      setErrors(true)
      console.log(e)
    }
  }

  const fetchMoreData = async (next = 0) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_KEY}shop/catalog_products/?lang=${lang}&region=${region}&category=${params.label}&start=${next}&amount=${postsPerPage}`)
      const data = await res.json()
      setCatalog(catalog.concat(data.results))
      setLoading(false)
    } catch (e) {
      setErrors(true)
      console.log(e)
    }
  }

  useEffect(() => {
    dispatch(setUrl(`${url}${params.category}/${params.label}`))
  }, [])

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(`${url}${params.category}/${params.label}`))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region, params])

  const spawnGalery = () => {
    return catalog.map((item, i) => <WholesaleProductCard key={i} item={item} params={params} />)
  }

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!catalog && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='catalog-page'>
        {/* <Breadcrumbs /> */}
        <PageTittle tittle={t('pages.wp_categories.title')} />
        <div className='mainscreen-wrapper'>
          <div className='main-screen container'>
            {catalog.length > 0 ? spawnGalery() : null}
          </div>
          <button className='button load-more' onClick={handleShowMorePosts}>{t('pages.catalog.loadmore')}</button>
        </div>
      </div>
    )
  }
}

WholesaleCatalog.defaultProps = {
  lang: 'ru',
  url: '/wholesales-catalog/',
  region: 'ukraine'
}

WholesaleCatalog.propTypes = {
  dispatch: PropTypes.func,
  url: PropTypes.string,
  lang: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(WholesaleCatalog)
