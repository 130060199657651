import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useTranslation } from 'react-multi-lang'
import { Link } from 'react-router-dom'
import {setLocation} from "@global/actions";
import {LOCAL_USER_GEO} from "@global/constants";

const RegionSelector = () => {
  const { region } = useSelector(({ global: { user } }) => ({ ...user }))
  const t = useTranslation()
  const dispatch = useDispatch()

  const locationHandler = (country) => {
    dispatch(setLocation(country))
    localStorage.setItem(LOCAL_USER_GEO, country)
  }

  if (region === 'ukraine') {
    return (
      <li className='has-sub-menu'>
        <Link onClick={() => locationHandler('Ukraine')} to='/'>{t('global.menu.regions.ukraine')}</Link>
        <div className='sub-menu'>
          <ul>
            {/*<li className='title'>*/}
            {/*  <Link onClick={() => locationHandler('Russia')} to='/russia'>{t('global.menu.regions.russia')}</Link>*/}
            {/*</li>*/}
            <li className='title'>
              <Link onClick={() => locationHandler('Europe')} to='/european'>{t('global.menu.regions.europe')}</Link>
            </li>
          </ul>
        </div>
      </li>
    )
  } else if (region === 'russia') {
    return (
      <li className='has-sub-menu'>
        <Link onClick={() => locationHandler('Russia')} to='/russia'>{t('global.menu.regions.russia')}</Link>
        <div className='sub-menu'>
          <ul>
            <li className='title'>
              <Link onClick={() => locationHandler('Ukraine')} to='/'>{t('global.menu.regions.ukraine')}</Link>
            </li>
            <li className='title'>
              <Link onClick={() => locationHandler('Europe')} to='/european'>{t('global.menu.regions.europe')}</Link>
            </li>
          </ul>
        </div>
      </li>
    )
  } else {
    return (
      <li className='has-sub-menu'>
        <Link onClick={() => locationHandler('Europe')} to='/european'>{t('global.menu.regions.europe')}</Link>
        <div className='sub-menu'>
          <ul>
            <li className='title'>
              <Link onClick={() => locationHandler('Ukraine')} to='/'>{t('global.menu.regions.ukraine')}</Link>
            </li>
            {/*<li className='title'>*/}
            {/*  <Link onClick={() => locationHandler('Russia')} to='/russia'>{t('global.menu.regions.russia')}</Link>*/}
            {/*</li>*/}
          </ul>
        </div>
      </li>
    )
  }
}

export default RegionSelector
