import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { hideLoader, renderDocuments, showLoader } from '@global/globalFunctions'

import Seo from '@components/Seo'
import NotFound from '@pages/404/index'

import '@scss/documents/index.scss'

const Certificate = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [certificatePage, setCertificatePage] = useState({
    certificates: [],
    title: 'Сертификаты',
    first_text: '',
    guarantee_text: ''

  })

  const [loading, setLoading] = useState(true)

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}certificates?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setCertificatePage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()

    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang, region)
  }, [lang, region])

  // console.log('CertificatePage', certificatePage)
  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  if (loading) {
    return null
  } else if (!certificatePage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content inner-pages certificate-page'>
        <Seo seo={{ title: certificatePage.seo_title, canonical: certificatePage.seo_canonical, description: certificatePage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: certificatePage.title }} />
            </div>
            <div className='column-3-4' dangerouslySetInnerHTML={{ __html: certificatePage.first_text }} />
          </div>
          <div className='container certificates'>
            {renderDocuments(certificatePage.certificates)}
          </div>
        </section>
      </div>
    )
  }
}

Certificate.defaultProps = {
  lang: 'ru',
  url: '/info/certificates',
  region: 'ukraine'
}

Certificate.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(Certificate)
