import React, { Component } from 'react'

export default class SecondStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit (event) {
    alert(`Сочинение отправлено: ${this.state.value}`)
    event.preventDefault()
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
            Почтовый индекс
        </label>

        <input type='text' placeholder='Почтовый индекс' />
        <label>
            Область / Регион
        </label>

        <input placeholder='Область / Регион' />

        <label>
            Район
        </label>

        <input placeholder='Район' />
        <label>
            Город
        </label>

        <input placeholder='Город' />

        <label>
            Улица
        </label>

        <input placeholder='Улица' />

        <label>
            Номер дома
        </label>

        <input placeholder=' Номер дома' />

        <label>
            Строение / Корпус / Номер офиса (необязательно)
        </label>

        <input placeholder='Строение / Корпус / Номер офиса (необязательно)' />

        <label>
            Номер квартиры (необязательно)
        </label>

        <input placeholder='Номер квартиры (необязательно)' />

        <input className='button' type='submit' value='Отправить' onClick={() => this.props.send()} />
      </form>
    )
  }
}
