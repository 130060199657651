import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { selectLang, selectRegion } from '@global/selectors'
import { connect } from 'react-redux'

const WholesaleProductCard = (props) => {
  const { item, lang, region, params } = props
  const [stateUrl, setStateUrl] = useState('')

  useEffect(() => {
    if (region === 'ukraine') {
      switch (lang) {
        case 'ru':
          setStateUrl('/ru/')
          break
        case 'uk':
          setStateUrl('/')
          break
        case 'en':
          setStateUrl('/en/')
          break
        default:
          break
      }
    } else if (region === 'russia') {
      switch (lang) {
        case 'ru':
          setStateUrl('/russia/')
          break
        case 'uk':
          setStateUrl('/russia/ua/')
          break
        case 'en':
          setStateUrl('/russia/en/')
          break
        default:
          break
      }
    } else if (region === 'european') {
      switch (lang) {
        case 'ru':
          setStateUrl('/european/ru/')
          break
        case 'uk':
          setStateUrl('/european/ua/')
          break
        case 'en':
          setStateUrl('/european/')
          break
        default:
          break
      }
    }
  }, [lang, region])

  return (
    <Link to={`${stateUrl}wholesales-catalog/${params.category}/${params.label}/${item.label}`} className='card-wrapper'>
      <div className='card'>
        <img className='card-image' src={item.photos[0].photo} alt='goods image' />
        <p className='card-tittle'>{item.title}</p>
      </div>
    </Link>
  )
}

WholesaleProductCard.propTypes = {
  lang: PropTypes.string,
  region: PropTypes.string,
  item: PropTypes.object,
  params: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  lang: selectLang(),
  region: selectRegion(),
})

export default connect(mapStateToProps, null)(WholesaleProductCard)
