import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation, setLanguage } from 'react-multi-lang'

import { connect } from 'react-redux'
import { setLang, setUrl, setRegion } from '@global/actions'

import { Link } from 'react-router-dom'

import {textContent, showLoader, hideLoader, setCurrentLang} from '@global/globalFunctions'
import PartnerSlider from '@components/PartnerSlider'
import Seo from '@components/Seo'

import NotFound from '@pages/404'

import '@scss/about/index.scss'
import '@scss/inner-pages/index.scss'

const AboutCompany = (props) => {
  const { dispatch, lang, url, region } = props

  const [hasError, setErrors] = useState(false)
  const [aboutPage, setAboutPage] = useState()
  const [partners, setPartners] = useState([])
  const [stateUrl, setStateUrl] = useState()
  const [loading, setLoading] = useState(true)

  const t = useTranslation()

  async function fetchData () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}about_page?lang=${lang}&region=${region}`)
    res.json()
      .then((res) => setAboutPage(res[0]))
      .then(() => setLoading(false))
      .catch((err) => setErrors(err))
  }

  async function fetchPartners () {
    const res = await fetch(`${process.env.REACT_APP_API_KEY}partners?lang=${lang}`)
    res.json()
      .then((res) => setPartners(res[0].partners))
      .catch((err) => setErrors(err))
  }

  useEffect(() => {
    showLoader()
    fetchData()
    fetchPartners()
    setCurrentLang(region, lang, setStateUrl)
    dispatch(setUrl(url))
    dispatch(setLang(lang))
    dispatch(setRegion(region))

    setLanguage(lang)
  }, [lang, region])

  if (!loading) {
    setTimeout(hideLoader, 500)
  }

  // console.log('About company', partners)
  // console.log('Params', param)

  if (loading) {
    return null
  } else if (!aboutPage && !loading) {
    return <NotFound />
  } else {
    return (
      <div className='page-content about-page inner-pages'>
        <Seo seo={{ title: aboutPage.seo_title, canonical: aboutPage.seo_canonical, description: aboutPage.seo_description }} />

        <section id='first-screen' className='first-screen'>
          <div className='container title-place'>
            <div className='column-1-4'>
              <h1 dangerouslySetInnerHTML={{ __html: aboutPage.title }} />
            </div>
            <div className='column-3-4 sub-title-text' dangerouslySetInnerHTML={{ __html: aboutPage.first_text }} />
          </div>
          {aboutPage.contents ? textContent(aboutPage.contents) : null}
          <div className='container'>
            <div className='column-1-4' />
            <div className='column-3-4'>
              <div dangerouslySetInnerHTML={{ __html: aboutPage.partners_text }} />

              {/* {partners ? <PartnerSlider data={partners} /> : null} */}
              <Link className='button' to={`${stateUrl}company/partners`}>{t('global.buttons.detail')}</Link>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

AboutCompany.defaultProps = {
  lang: 'ru',
  url: '/company',
  region: 'ukraine'
}

AboutCompany.propTypes = {
  dispatch: PropTypes.func,
  lang: PropTypes.string,
  url: PropTypes.string,
  region: PropTypes.string
}

export default connect(null, null)(AboutCompany)
