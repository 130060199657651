import produce from 'immer'
import {
  SET_CATEGORIES,
  SET_BRAND,
  SET_MATERIALS,
  SET_PRICE,
  REMOVE_CATEGORIES,
  REMOVE_BRAND,
  REMOVE_MATERIALS,
  SET_CATEGORIES_LIST,
  SET_COLOR,
  REMOVE_COLOR,
  CLEAR_SELECTOR
} from './constants'

// Initial State
export const initialState = {
  categoriesList: [],
  categories: [],
  materials: [],
  price: {
    min: null,
    max: null
  },
  brands: [],
  colors: []
}

const filterReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SET_CATEGORIES:
      draft.categories.unshift(action.categories)
      break
    case SET_CATEGORIES_LIST:
      draft.categoriesList = action.list
      break
    case REMOVE_CATEGORIES:
      const temp = draft.categories.filter((item) => item !== action.categories)
      draft.categories = temp
      break
    case SET_COLOR:
      draft.colors.unshift(action.colors)
      break
    case REMOVE_COLOR:
      const tempC = draft.colors.filter((item) => item !== action.colors)
      draft.colors = tempC
      break
    case SET_BRAND:
      draft.brands.unshift(action.brands)
      break
    case REMOVE_BRAND:
      const tempB = draft.brands.filter((item) => item !== action.brands)
      draft.brands = tempB
      break
    case SET_MATERIALS:
      draft.materials.unshift(action.materials)
      break
    case REMOVE_MATERIALS:
      const tempM = draft.materials.filter((item) => item !== action.materials)
      draft.materials = tempM
      break
    case SET_PRICE:
      draft.price = action.price
      break
    case CLEAR_SELECTOR:
      draft[action.selector] = []
      break
    default:
      return state
  }
})

export default filterReducer
